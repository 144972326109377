import React from 'react'

const ImageLabels = ( { labels } ) => {
    // console.log(labels);
    const labellist = labels?.map((lbl, index)=>(
            <div key={index} style={{
                position:'absolute',
                bottom: '150px'
              }}>
              <div style={{position:'relative', display:'inline-block', top:`${-index*50+80}px`, left:'10px', height:'40px', zIndex:'5', 
                paddingLeft:'20px', paddingRight:'20px',fontSize:'12px', lineHeight:'40px', color:'black', 
                borderRadius: '25px', backgroundColor: '#f1f1f1'}}>
                {lbl}
              </div>
              </div>
    ));

    return (    
    <>
    { labellist }
    </>
  )
}

export default ImageLabels