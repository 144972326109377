import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import './Toast.css';
import { Button } from '@mui/material';


const YesNoToast = (props) => {

    const { toastList, position, message, onClose } = props;
    const [list, setList] = useState(toastList);
    const [value, setValue] = React.useState(0);
    const liveValue = useRef(value);

    const handleRadioChange = (event) => {
      setValue(event.target.value);
      console.log(event.target.value);
      // val = event.target.value;
    };

    useEffect(() => {
        setList(toastList);
        // val = 0;
      }, [toastList]);

    const clearList = id =>{
      const index = list.findIndex(e => e.id === id);
      list.splice(index, 1);
      setList([]);
    }

    const deleteToast = (id) => {      
        setValue(0);
        clearList(id);      
        onClose(liveValue.current);
    }
    
    const closeToast = (id, cowid) => {      
      clearList(id);      
      onClose(value, cowid, );
  }
  
    return (
        <>
    <div className={`notification-container ${position}`} >
      {
        list.map((toast, index) =>
        <div key={index} 
        style={{height:'100px', }}
          className={`notification-yesno toast ${position}`}>
            <div style={{display:'flex', flexDirection:'column'}}>
              <div className="notification-image" style={{display:'flex', justifyContent:'space-between',
                width:'350px', paddingRight:'10px',}} >
                    <img src={toast.icon} alt="" width={'20px'} />
                    <span style={{color:'black', fontWeight:'bold'}}>{toast.description}</span>   
                    <button onClick={() => deleteToast(toast.id)}>
                      x
                    </button>
              </div>
              <div style={{color:'black', fontWeight:'bold'}}>{message} </div>            
              <div>              <br/></div>
              <div style={{display:'flex', justifyContent:'center' }}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={ value }
                        onChange={handleRadioChange}>
                        <FormControlLabel value= "0" control={<Radio />} sx={{color:'black'}} label="Not Pregnant" />
                        <FormControlLabel value="1" control={<Radio />} sx={{color:'black'}} label="Is Pregnant" />
                    </RadioGroup>
                    <Button variant='text' color='primary' onClick={closeToast}>Ok</Button>
              </div>
            </div>
          </div>)
      }
      </div>

        </>
      );
}

export default YesNoToast