import React from 'react';
import { useContext } from 'react';
import DataContext from '../Context/DataContext';
import ProgressIndicator from '../Indicator/ProgressIndicator';

import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Doughnut } from 'react-chartjs-2';
  
   
ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const CowType = () => {
  const { listCowTypes, listCowTypeLabel } = useContext(DataContext);
 
  // console.log(listCowTypes);
  const data = {
    labels: listCowTypeLabel,        
    datasets: [
      {
        label: 'Type',
        data: listCowTypes,                
        options: {
          percentageInnerCutout: 10,
          responsive: true,           
          Plugins:{
            legend: {
              position: 'bottom',
            },
            title:{
              display: true,
              texct: 'Break up'
            }
          }
        },
        backgroundColor: [
          'rgb(62, 73, 179)',
          'rgb(87, 150, 250)',
          'rgb(37, 247, 244)',    
          'rgb(33, 255, 37)',          
          'rgb(252, 189, 244)',
        ],
        borderWidth: 1,
      },
    ],
  };
// const options= {
//   showDatasetLabels : true,
//   cutoutPercentage: 41,
//   legend: {
//     display: true, 
//     position:'bottom',
//     labels: {
//       fontFamily: "myriadpro-regular",
//       boxWidth: 15,
//       boxHeight: 2,
//     },
// } 
// };

const option ={
  responsive: true,
  Plugins: {
    legend: {
        position: "bottom",
        align: "middle"
    },
    cutoutPercentage: 80,
  },
};

  return (
    <>
    {
      listCowTypes ?  <Doughnut data={data} options={option} sx={{        
        innerWidth:'80%',
        justifyContent: 'center'
    }}/> :
      <ProgressIndicator></ProgressIndicator>
    }       
    </>
  )
}

export default CowType