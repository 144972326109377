import {React, useState} from 'react'
import { Typography, Select,InputLabel, MenuItem } from '@mui/material'
const SelectIVFOptions = ({onSelectChange}) => {

    const [optionValue, setoptionValue] = useState('none');

    const handleChange = (event) => {
        let returnvalue = event.target.value;
        setoptionValue(returnvalue);
        onSelectChange(returnvalue);
      };

      
    return (
    <div>
      <Typography > 
      <InputLabel id="simple-select-label">IVF Type</InputLabel>
          </Typography>
          <Typography >        </Typography>        
              <Select
                labelId="ivfType"
                id="ivfType"
                label="IVF Type"
                value={optionValue}
                onChange={ handleChange }
                > 
                <MenuItem value="Dr Vikas Team">Dr Vikas Team</MenuItem>
                <MenuItem value="Radhe">Radhe</MenuItem>
                <MenuItem value="Dr Vikas">Dr Vikas</MenuItem>
                <MenuItem value="Samser">Samser</MenuItem>                    
                <MenuItem value="Localbull">Local Bull</MenuItem>                                    
                <MenuItem value="none">Select Doc...</MenuItem>
            </Select>

    </div>
  )
}

export default SelectIVFOptions