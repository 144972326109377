import React from 'react'
import PropTypes from 'prop-types';
// import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
// import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';



function SimpleDialog(props) {
  const { onClose, selectedValue, open, items } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select Animal Type</DialogTitle>
      <List sx={{ pt: 0 }}>
        {items.map((item) => (
          <ListItem key={item} disableGutters>
            <ListItemButton onClick={() => handleListItemClick(item)} key={item}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item}/>
            </ListItemButton>
          </ListItem>
        ))}

      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };

const OptionsDialog = ({dialogClose, val, openOption, updateType, valueList}) => {
    // console.log("value passed " + val);
//{dialogClose, val, openOption} 

    const items = valueList;

    const [open, setOpen] = React.useState(openOption);
    const [selectedValue, setSelectedValue] = React.useState(val);
    // const [selectedValue, setSelectedValue] = React.useState(items[1]);
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = (value) => {
      setOpen(false);
      setSelectedValue(value);
      // console.log("hello " + openOption + " updtype "+ updateType + " selected " + selectedValue);
      dialogClose(value, updateType);
    };

  return (
    <div>
        <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }} component="div">
        {selectedValue}
      <EditIcon color='primary' onClick={ ()=>{
            //   handleDialogOpen("Add Remarks", 
            //   "text", 
            //   "Enter your remarks below", 2);
            handleClickOpen();      
        }}/></Typography>      
            {/* </EditIcon> */}
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open simple dialog
      </Button> */}
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        items={items}
      />        
    </div>
  )
}

export default OptionsDialog