import React from 'react'
import { useContext, useState, useEffect } from 'react';
import DataContext from '../Context/DataContext';
import Title from '../Title/Title';
import ExpenseTrendChart from './ExpenseTrendChart';
import { type } from '@testing-library/user-event/dist/type';

const ExpenseTrend = () => {
    const { ExpenseChartData } = useContext(DataContext);
    const [ salaryList, setSalaryList]= useState([]);
    const [ chartlist, setChartlist]= useState([[]]);
    const [ typelist, setTypelist]= useState([[]]);    

// console.log(ExpenseChartData);    

useEffect(()=>{
    const GenerateChartList = ()=>{
    }
    // console.log(`typelist.length ${typelist.length}`)
    if(typelist.length === 1) {
        setTypelist(
            [
                ...new Set(ExpenseChartData.map(item=>item['TYPE']))
            ]
        );
    }
    GenerateChartList();
});

useEffect(()=>{
    let ablist =[];
    // console.log(typelist);
    typelist.map((type)=>(        
        ablist[type] = ExpenseChartData.filter( x => (x.TYPE === type))
    ));
    // console.log(ablist);

    setChartlist(ablist)

}, [typelist]);

// useEffect(()=>{
    // console.log("abbbbbb");
    // typelist.map((type)=>(
    //     console.log(chartlist[type])
    // ))
// },[typelist, chartlist])

return (
    <>
    {
        // console.log(chartlist)
    }
    <Title title={"Expense Trend"} />
    {
           
     typelist.map((type, index)=>(
        <ExpenseTrendChart key={index} title={type} data={chartlist[type]} />
     ))   
    }
    <div>Expense Trend</div>
    </>
  )
}

export default ExpenseTrend