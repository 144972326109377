import React from 'react';
// import { Link } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useNavigate, useLocation } from 'react-router-dom';

const BackButton = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const goBack = () => {
      console.log(location.pathname);
        navigate(-1);
        // Navigation.goBack();
      };

      return (
        <div onClick={goBack}>          
          {/* <Link to={'..'}>  */}
          <ArrowCircleLeftIcon variant="contained" sx={{color:'blue'}} onClick={goBack} />
          {/* </Link>         */}
        </div>
      );
}

export default BackButton