import React from 'react'
import { useContext, useState, useEffect } from 'react';
import DataContext from '../Context/DataContext';
import { useNavigate } from 'react-router-dom';
import BasicImageList from './BasicImageList';
import ProgressIndicator from '../Indicator/ProgressIndicator';

const ConfirmPregnancy = () => {
    const { isLoading, items } = useContext(DataContext);
    const [imagelist, setImageList] = useState([]);

    // console.log(items);
    
    let navigate = useNavigate();
    useEffect(()=>{
      if (!isLoading){
        setImageList(
            items.map(item =>{         
              const infolist = [
                `Days to delivery: ${item.dayssinceiv===999?'NA': 270-item.dayssinceiv}`,
                `Days since deworming: ${item.dayssincedeworming ===999?'NA': item.dayssincedeworming}`
              ];
                return (
                    [
                        item.cowid,
                        item.image1,
                        item.tag,
                        item.status,
                        item.isMilking,
                        item.isVerified,
                        item.isPregnant,
                        infolist
                    ]
                );        
            }).filter(value => (value[3] === 'Adult Female' & value[6] === 1))
        );
      }
    },[isLoading]);
    // console.log(items);

  return (
    <>
     {isLoading && <ProgressIndicator /> }
    {    !isLoading && <BasicImageList title={"Pregnancy Confirmed"} imglist={imagelist}  />}
    </>
  )
}

export default ConfirmPregnancy