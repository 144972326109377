import React from 'react'
import { useContext } from 'react';
import DataContext from '../Context/DataContext';
import { useNavigate } from 'react-router-dom';
import BasicImageList from './BasicImageList';

const CowImageList = () => {
    let navigate = useNavigate();
    const { items } = useContext(DataContext);
    // console.log(items);
    const imagelist = items.map(item =>{         
        return (
            [
                item.cowid,
                item.image1,
                item.tag,
                item.status,
                item.isMilking,
                item.isVerified
            ]
        );        
    }).filter(value => value[1] !== '');    

// console.log(imagelist);

  return (
    <BasicImageList title={"Cow Image List"} imglist={imagelist}  />
    // <>
    // <Title title={"Cow Image List"} />
    // <ImageList sx={{ width: "95vw", height: "95vh" }}>
    //   <ImageListItem key="Subheader" cols={2}>
    //     {/* <ListSubheader component="div">Image List</ListSubheader> */}
    //   </ImageListItem>
    //   {imagelist.map((item) => (
    //     <ImageListItem key={item[0]}>
    //       <img
    //         src={`${"https://sasyavatagro.com/img/farmimg/" + item[1]}?w=248&fit=crop&auto=format`}
    //         srcSet={`${"https://sasyavatagro.com/img/farmimg/" + item[1]}?w=248&fit=crop&auto=format&dpr=2 2x`}
    //         alt={item[0]}
    //         loading="lazy"
    //       />
    //       <ImageListItemBar
    //         title={"Tag : "  + item[2]}
    //         subtitle={item[3]}
    //         actionIcon={
    //           <IconButton
    //             sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
    //             aria-label={`info about ${item[0]}`}
    //             onClick={
    //                 ()=>{
    //                     navigate("/cowdetail/"+ item[0], { replace: true })
    //                     console.log("Hello");
    //                 }
    //             }
    //           >
    //             <InfoIcon />
    //           </IconButton>
    //         }
    //       />
    //     </ImageListItem>
    //   ))}
    // </ImageList>
    // </>

  )
}

export default CowImageList