import {React, useState} from 'react'
import { Typography, Select,InputLabel, MenuItem } from '@mui/material'
const SelectMedicineOptions = ({onSelectChange}) => {

    const [optionValue, setoptionValue] = useState('none');

    const handleChange = (event) => {
        let returnvalue = event.target.value;
        setoptionValue(returnvalue);
        onSelectChange(returnvalue);
      };

      
    return (
    <div>
      <Typography > 
      <InputLabel id="demo-simple-select-label">Medicine</InputLabel>
          </Typography>
          <Typography >        </Typography>
              <Select
                labelId="medicinetype"
                id="medicinetype"
                label="Medicine"
                value={optionValue}
                onChange={ handleChange }
                > 
                <MenuItem value="Others">Others</MenuItem>
                <MenuItem value="Hitek">Hitek</MenuItem>
                <MenuItem value="Nilzan">Nilzan</MenuItem>
                <MenuItem value="Ascominth">Ascominth</MenuItem>                    
                <MenuItem value="none">Select Medicine...</MenuItem>
            </Select>

    </div>
  )
}

export default SelectMedicineOptions