import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InsertCommentSharpIcon from '@mui/icons-material/InsertCommentSharp';
import {IconButton} from '@mui/material';

const MultiLineTextDialog = ({dialogClose, updateType}) => {
  const [open, setOpen] = React.useState(false);
  const [textValue, setTextValue] = React.useState("");

// console.log(open);
  // setOpen(openVal);

  const handleClose = (val) => {
    console.log(val);

    if(val !== -1){    
      dialogClose(updateType, textValue);
    }
    
    if(textValue.length>0) setTextValue("");

    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={()=>{ 
            console.log("Hello");
            setOpen(true);
            }}>
            <InsertCommentSharpIcon variant="outlined" sx={{
              color: "red"
              }}/>
        </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Remarks</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter remarks
          </DialogContentText>
          <TextField          
            autoFocus
            margin="dense"
            id="name"
            label="remarks"
            type="text"
            multiline= {true}
            fullWidth
            variant="standard"
            value={textValue}
            onChange={(e)=>setTextValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            handleClose(-1);
            }}>Cancel</Button>
          <Button onClick={handleClose}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MultiLineTextDialog