import React from 'react'
import ProductionTrend from './ProductionTrend'
import Title from '../Title/Title'
import CowType from '../Charts/CowType'
import {FetchProvider} from '../Context/FetchContext'

const Dashboard = () => {
  return (
    <FetchProvider>
    <Title title={"Dashboard"} />
    <div className='ChartContainerProductionTrend'>
    <ProductionTrend />
    </div>
    <div className='ChartContainerCowType'> 
          <CowType />   
        </div> 
    </FetchProvider>
  )
}

export default Dashboard