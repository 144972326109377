import React, {useState, useEffect, useContext} from 'react';
import DataContext from '../Context/DataContext';
import { Box } from '@mui/material';
import {IconButton} from '@mui/material';
import axios from 'axios';
import GppGoodSharpIcon from '@mui/icons-material/GppGoodSharp';
import EggSharpIcon from '@mui/icons-material/EggSharp';
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import DynamicFeedSharpIcon from '@mui/icons-material/DynamicFeedSharp';
import MultiLineTextDialog from '../Dialog/MultiLineTextDialog';

const HighlightBoard = ({cowid, isverified, ismilking}) => {
    let navigate = useNavigate();
    const {UpdateCowRemarks, InitiateRefresh}=useContext(DataContext);
    const[isVerifiedValue, setisVerified] = useState(isverified);
    const[isMilkingValue, setisMilking] = useState(ismilking);
    const[isLoading, setLoadingValue] = useState(false);
    const [fetchError, setFetchError] = useState(null);

    // console.log("hb cowid : " + cowid);
    // console.log("hb verified : " + isverified);
    // console.log("hb milking : " + ismilking);

    const HandleDate =  () => {
        navigate("/updatedate/"+ cowid);
        // '/updatedate/'+ item.cowid
    }

    const HandleMilking = async () => {
        console.log(isMilkingValue);
        var newValue = isMilkingValue;
        if(isMilkingValue === 1){
            newValue = 0;
            setisMilking(0);
        } else {
            newValue = 1;
            setisMilking(1);
        }

        const updatedVerifiedPost = {
            cowid: cowid, 
            ismilking: newValue
         }
        setLoadingValue(true);
        console.log("data " + JSON.stringify(updatedVerifiedPost))
         try{
            const response = await axios.post(
                `https://www.sasyavatagro.com/repos/api/DairyFarm/UpdateMilkingStatus`, 
                updatedVerifiedPost);
                InitiateRefresh();
              console.log(JSON.stringify(response));
            }
        catch (err){
            setFetchError(err.message);
        } finally {
            setLoadingValue(false);
        }
    }

    const HandleVerified = async () => {

        // console.log(isVerifiedValue);
        var newValue = isVerifiedValue;
        if(isVerifiedValue === 1){
            newValue = 0;
            setisVerified(0);
        } else {
            newValue = 1;
            setisVerified(1);
        }

        const updatedVerifiedPost = {
            cowid: cowid, 
            isverified: newValue
         }
        
        // console.log("data " + JSON.stringify(updatedVerifiedPost))
        setLoadingValue(true);

        try{
            const response = await axios.post(
                `https://www.sasyavatagro.com/repos/api/DairyFarm/UpdateVerifiedStatus`, 
                updatedVerifiedPost);
                InitiateRefresh();
              console.log(JSON.stringify(response));
            }
        catch (err){
            setFetchError(err.message);
        } finally {
            setLoadingValue(false);
        }
    }
    useEffect(() => {
    }, [isLoading])
    
  return (
    <>
    { isLoading && <CircularProgress color="secondary" /> }

    { !isLoading && 
    <Box display="flex" justifyContent="space-evenly" sx={{
        backgroundColor: "beige",
        padding:"2px",
        borderRadius:"10px",
    }}>
        <IconButton onClick={ HandleVerified }>
            <GppGoodSharpIcon color= {isVerifiedValue===1 ? 'success' :'disabled'} />
        </IconButton>
        <IconButton onClick={ HandleMilking }>
            <EggSharpIcon color= {isMilkingValue===1 ? 'primary' :'disabled'} />
        </IconButton>
        <IconButton  onClick={()=>{
                navigate("/cowhistory/"+ cowid);
            }}>
            <DynamicFeedSharpIcon variant="contained" color='success'/>
        </IconButton>
        <MultiLineTextDialog dialogClose = {(updateType, returmtext)=>{
            console.log("in highlight " + updateType);            
            console.log(returmtext);
            UpdateCowRemarks(cowid, returmtext);
        }} updateType = {3} />
        <IconButton onClick={ HandleDate }>
            <CalendarMonthSharpIcon variant="outlined" color="error" />
        </IconButton>
    </Box>
}

</>
  )
}

export default HighlightBoard