import React from 'react'
import { Typography } from '@mui/material'
// import { lightGreen } from '@mui/material/colors';

const IndicatorSimple = ({ size, cls, msg, verified, v_color }) => {
let is_verified = -1;
  if(verified==null) {
    is_verified=-1;
  } else {
    is_verified=verified;
  }
  // console.log("is verified : " + is_verified);
    return (    
    <div style={{position:'relative', display:'flex', justifyContent:'space-between', 
        backgroundColor: is_verified === 1
       ? 'lightgreen': is_verified === 2
       ? 'lightgrey': is_verified === 0 ? 'lightgrey': 'transparent', paddingRight:'30px', 
    borderTopLeftRadius:'20px', borderBottomLeftRadius:'20px', borderTopRightRadius:'20px'}}>
    <center className={cls}>{size}</center>

    <Typography variant='body3' sx={{fontWeight:'bold', lineHeight:'27px',
      fontSize:"small", alignItems:'center', alignContent:'center'
    }}>
    <span style={{paddingLeft:"10px",  fontSiz:'12px'}}>
      {msg}</span></Typography></div>
  )
}

export default IndicatorSimple