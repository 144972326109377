import './CowHistory.css'
import { Grid } from '@mui/material'
import React from 'react'
import { format } from 'date-fns'

const RemarksTagContent = ({item}) => {
  return (
    <>
    <Grid className='chContainer' container sx={{
        padding:"10px"
    }}>    
    <Grid className='chDate' item xs={12} textAlign="left">
       Remarks updated on {format(new Date(item.CreateDate), "dd MMM yyyy")} 
    </Grid>
    <Grid item xs={12} textAlign="left">
        { item.Detail } 
    </Grid>
    
    </Grid>
    </>
  )
}

export default RemarksTagContent