import React, { useEffect, useState } from 'react'
import Title from '../Title/Title'
import useAxiosFetch from '../Hooks/useAxiosFetch';
import { useParams } from 'react-router-dom';
import BasicImageList from './BasicImageList';
import ProgressIndicator from '../Indicator/ProgressIndicator';

const BirthingIVList = ({ yearmonth }) => {
    const routeParams = useParams();
    const {data, fetchError, isLoading} = 
        useAxiosFetch("https://sasyavatagro.com/repos/api/dairyfarm/GetBirthingIVList?ym=" + routeParams.yearmonth);

    const [dataItems, setDataItems]= useState([]);
    useEffect(()=>{
        try{
            console.log(isLoading);
            if(data.status===200){
                let items = data.data;
                // console.log(items[0]);
                // var result = Object.keys(items).map((key)=>[Number(key), items[key]]);
                
                setDataItems(
                    items.map(item =>{ 
                        const infolist = [
                            `Days since last delivery: ${item.dayssincelastdelivery}`,
                            `Days since IV: ${item.dayssinceiv===999?'NA': item.dayssinceiv}`,
                            `Days since deworming: ${item.dayssincedeworming ===999?'NA': item.dayssincedeworming}`
                          ];
                        var x = Object.values(item);
                        // console.log(x[1]);
                        return(
                            [
                            x[0],
                            x[1],
                            x[2],
                            x[3],
                            infolist
                        ]
                        );
                    }   
                 )
                )
            }
        } catch (err){
            console.log(err)
        } finally{
            // console.log(data["data"] );
        }
    },[isLoading]);

    return (
    <>
    {isLoading && <ProgressIndicator />}
    {!isLoading && 
        <BasicImageList title={"Birthing IV List"} imglist={dataItems}  />
    }
    </>
  )
}

export default BirthingIVList