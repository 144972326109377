import { useState, useEffect, useContext} from "react";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import DataContext from './Components/Context/DataContext';
import Cowlist from './Components/CowList/Cowlist';
import UpdateDate from './Components/Date/UpdateDate';
import CowDetail from './Components/CowDetail/CowDetail';
import CowImageList from './Components/CowImageList/CowImageList';
import HeiferImageList from './Components/CowImageList/HeiferImageList';
import AdultFemaleImageList from './Components/CowImageList/AdultFemaleImageList';
import AwaitingIVImageList from './Components/CowImageList/AwaitingIVImageList';
import CowHistory from './Components/CowHistory/CowHistory';
import { StyledEngineProvider } from '@mui/material/styles';
import Layout from './Components/Layout';
import HomePage from './HomePage';
import BirthingSchedule from "./Components/BirthingSchedule/BirthingSchedule";
import BirthingIVList from "./Components/CowImageList/BirthingIVList";
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import DewormingImageList from "./Components/CowImageList/DewormingImageList";
import AdultNonMilking from "./Components/CowImageList/AdultNonMilking";
import ConfirmPregnancy from "./Components/CowImageList/ConfirmPregnancy";
import SuspectPregnancy from "./Components/CowImageList/SuspectPregnancy";
import CowDetailUpdateMilk from "./Components/CowDetail/CowDetailUpdateMilk";
import CurrentMilking from "./Components/Dashboard/CurrentMilking";
import LongMissingAI from "./Components/CowImageList/LongMissingAI";
import OtherCows from "./Components/CowImageList/OtherCows";
import OtherCowsList from "./Components/CowList/OtherCowsList";
import ExpenseTrend from "./Components/Dashboard/ExpenseTrend";
import SixMonthPregnantList from "./Components/CowImageList/SixMonthPregnantList";
import EightMonthsPregnantList from "./Components/CowImageList/EightMonthsPregnantList";

function App() {
  const { InitiateRefresh } = useContext(DataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [pressed, setPressed] = useState(false);

// useEffect(()=>{
// window.onpopstate =()=>{
//   console.log("pressed " + pressed);
//   console.log(location.pathname);
//   setPressed(!pressed);
  
//   if(location.pathname === "/CowList" || location.pathname === '/CowImageList'){
//     console.log("Hello");
//     InitiateRefresh();
//   }

//   if(location.pathname === "/"){
//     navigate('/');
//   }
// }
// })

return (

<StyledEngineProvider injectFirst>
      <Routes>
        <Route path='/' element={<Layout/>} >
        <Route index element={ <HomePage /> } />
        <Route path='/cowlist' element={<Cowlist />} />
        <Route path='/CowImageList' element={<CowImageList />} />
        <Route path='/HeiferImageList' element={<HeiferImageList />} />
        <Route path='/AdultFemaleImageList' element={<AdultFemaleImageList />} />
        <Route path='/AwaitingIVImageList' element={<AwaitingIVImageList />} />
        <Route path='/DewormingImageList' element={<DewormingImageList />} />
        <Route path='/AdultNonMilking' element={<AdultNonMilking />} />
        <Route path='/BirthingSchedule' element={<BirthingSchedule />} />        
        <Route path='/Dashboard' element={<Dashboard />} />
        <Route path='/Login' element={<Login />} />
        <Route path='/Register' element={<Register />} />
        <Route path='/updatedate/:cowid' element={<UpdateDate />} />
        <Route path='/cowdetail/:cowid' element={<CowDetail />} />
        <Route path='/cowhistory/:cowid' element={<CowHistory />} />
        <Route path='/birthingIVList/:yearmonth' element={<BirthingIVList />} />
        <Route path="/ConfirmPregnancy" element={<ConfirmPregnancy />} />
        <Route path="/SuspectPregnancy" element={<SuspectPregnancy />} />
        <Route path="/UpdateMilk/:cowid" element={<CowDetailUpdateMilk />} />       
        <Route path="/CurrentMilking" element={ <CurrentMilking />} />
        <Route path="/LongMissedAI" element={ <LongMissingAI />} />
        <Route path="/OtherCows" element={ <OtherCowsList />} />
        <Route path="/ExpenseTrend" element={ <ExpenseTrend />} />
        <Route path="/SixMonthsPregnant" element={ <SixMonthPregnantList />} />        
        <Route path="/EightMonthsPregnant" element={ <EightMonthsPregnantList />} />        

          {/* <Route index ></Route> */}
          {/* </Route> */}
          </Route>
      </Routes>
    </StyledEngineProvider>

  );
}

export default App;
