import './CowHistory.css'
import { Grid } from '@mui/material'
import React from 'react'
import { format } from 'date-fns'

const MilkingUpdateContent = ({item}) => {
  return (
    <>
    <Grid className='chContainer' container sx={{
        padding:"10px"
    }}>    
    <Grid className='chDate' item xs={12} textAlign="left">
        {format(new Date(item.CreateDate), "dd MMM yyyy")} 
    </Grid>
    <Grid item xs={12} textAlign="left">
        Milking { (item.Detail.substring(item.Detail.length-1).trim()/1) === 1 ? 
            "Started" : "Stopped"} on {format(new Date(item.TagDate), "dd MMM yyyy")} 
    </Grid>
    
    </Grid>
    </>
  )
}

export default MilkingUpdateContent