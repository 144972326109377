import React, { useEffect, useState } from 'react'
// import ProgressIndicator from '../Indicator/ProgressIndicator';
import WhileLoading from '../Indicator/WhileLoading';
import { useContext } from 'react';
import DataContext from '../Context/DataContext';
import Title1 from '../Title/Title';
import { Bar } from 'react-chartjs-2';
import MilkingRangeList from './MilkingRangeList'
import {Button, Card, CardContent, Typography} from '@mui/material';
import UpdateMilking from './UpdateMilking';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Box, Grid } from '@mui/material';
// import { Height } from '@mui/icons-material';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const CurrentMilking = () => {
    const { isLoading, listMilking, listApproachingDelivery, AdultFemaleTagList,
      ClearAllMilking, GetCowMilkTrendData} = useContext(DataContext);
    const [dataItems, setDataItems]= useState([]);
    const [dataLabels, setDataLabels]= useState([]);
    const [highMilkTagList, setHighhMlkTagList] =useState([]);
    const [midMilkTagList, setMidMilkTagList] = useState([]);
    const [lowMIlkTagList, setLowMilkTagList]=useState([]);
    const [milkdate, setMilkDate]=useState([]);
    const [milkvalue, setMilkValue]=useState([]);
    const [milkchartdata, setMilkChartData]=useState([]);
    const [currentMilkingStatus,setCurrentMilkingStatus]=useState(0);
    const [currentMilkingValue,setCurrentMilkingValue]=useState([]);
    // console.log(AdultFemaleTagList);
    console.log(listMilking);
    useEffect(()=>{
    
      var data = listMilking.map(item => {
        var dt = Object.values(item);
        // console.log(dt[11]);
        return(          
          dt[11]
      );
      });
      
      setDataItems(data);
        var lbl = listMilking.map(item => {
            var val = Object.values(item);
            var vstr = String(val[7])
            return(
              vstr
            );
      });

    setDataLabels(lbl);

    var lm_lst = listMilking.filter(x=> (x.lastmilkingqty <= 5)).map(item => {
      var val = Object.values(item);
        return val[7];
    }); 

    setLowMilkTagList(lm_lst);

    var mm_lst = listMilking.filter(x=> (x.lastmilkingqty > 5 && x.lastmilkingqty < 10)).map(item => {
      var val = Object.values(item);
        return val[7];
    }); 

    setMidMilkTagList(mm_lst);

    var hm_lst = listMilking.filter(x=> (x.lastmilkingqty >=10)).map(item => {
      var val = Object.values(item);
        return val[7];
    }); 

    setHighhMlkTagList(hm_lst);

    
    },[isLoading, listMilking]);

    const getMilkChartData = async (id)=>{

      let da = await GetCowMilkTrendData(id);      
      // console.log(da);             
      setMilkChartData(da);
  
      }
      
      useEffect(()=>{
        const getCurrentmilkingdata = async ()=>{
          // console.log('hello ' + newcowtag);
          const API_URL = 'https://www.sasyavatagro.com/repos/api/dairyfarm/GetCurrentMilkData';
            try{
              const response = await fetch(API_URL);
              if (!response.status) throw Error("Failed to fetch data");
              let valuelist = await response.json();
              console.log(valuelist);      
              setCurrentMilkingValue(valuelist);
            }
            catch (err) {
              console.log(err);
            }
            finally {
              setCurrentMilkingStatus(1);
            }    
        };

        getCurrentmilkingdata();

      },[]);

      useEffect(()=>{

        let dt_lst = milkchartdata.map(item => {
          var val = Object.values(item);
            return val[1];
        }); 
        
        setMilkValue(dt_lst);

        let dt_lbl= milkchartdata.map(item => {
          var val = Object.values(item);
            return val[2].substring(0, 5);
        });

        // console.log(dt_lbl);
        // console.log(dt_lst);
        setMilkDate(dt_lbl);

      },[milkchartdata])

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Total Milk by Cow',
        },
      },
      scales: {
        y: {
          min: 0,      
            ticks: {
             stepSize: 1, 
            }      
          }
      }
    };
    
    const chartdata = {
      labels: dataLabels,
      datasets: [
        {
          label: 'Qty',
          data: dataItems,
          borderColor: '#0f03fc',
          backgroundColor: '#eae8fa',
          borderWidth: 1,
        },
      ],
    };
  
    const datamilkchart = {
      labels: milkdate,
      datasets: [
        {
          label: 'Milk',
          data: milkvalue,
          borderColor: '#0f03fc',
          backgroundColor: '#eae8fa',
          borderWidth: 1,
        },
      ],
    };

    const selectedCow=(val)=>{
      // console.log(val);
      getMilkChartData(val);
    }

    
  return (
    <div style={{position:'relative', width:'100%', display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
    <Title1 title={"Milking Status"} />
    <>
    {dataItems.length>0 ?  
    <Box sx={{position:'relative', width:'98%'}}>
    <Bar options={options} data={chartdata} sx={{        
        innerWidth:'80%',
        justifyContent: 'center'
      }} /> 
      </Box>
    : 
    <></>
    // <ProgressIndicator />
    }
    <Grid container spacing={0} 
      sx={{position:'relative', paddingTop:'20px', paddingLeft:'10px', 
          paddingRight:'10px',  width:'98%',
          backgroundColor:'#F5EEF8'}}>
      <Grid item xs={4}>
        <MilkingRangeList Title={'Low Range'} Description='0 to 5 ltrs' Data={lowMIlkTagList}/>
      </Grid>
      <Grid item xs={4}>        
        <MilkingRangeList Title={'Mid Range'} Description='5 to 10 ltrs' Data={midMilkTagList}/>
      </Grid>
      <Grid item xs={4}>
        <MilkingRangeList Title={'High Range'} Description='Above 10 ltrs' Data={highMilkTagList}/>
      </Grid>
    </Grid>
    <Box sx={{position:'relative', width:'98%', marginTop:'10px', backgroundColor:'#D5F5E3', 
      marginBottom:'10px', marginLeft:'5px', marginRight:'5px', borderRadius:'10px'}}>
    <Typography fontSize={12} fontWeight={'bold'} color={'darkblue'} 
        sx={{paddingTop:'20px', paddingBottom:'20px'}}
        >
    Approaching delivery: 
    {
      listApproachingDelivery.map( (item, index) => (      
          <label key={index} style={{padding:'10px'}}>  {item.tag} ({275-item.dayssinceiv}) </label>
      ))
    }
    </Typography>
    </Box>
        <Box sx={{position:'relative', width:'98%', minHeight:'50px', marginTop:'10px', backgroundColor:'#89CDF9', 
      marginBottom:'10px', marginLeft:'5px', marginRight:'5px', borderRadius:'10px', justifyContent:'center'}}>
      {currentMilkingStatus === 0 ? <div sx={{Height:'20px'}}><WhileLoading/></div>  : 
      <div>
        <div style={{padding:'10px', display:'flex', justifyContent:'space-evenly', backgroundColor:'cadetblue', 
          borderTopLeftRadius:'10px', borderTopRightRadius:'10px' }}>
            <span>Cow Tag</span>
            <span>Quantity</span>
            <span>Days Since delivery</span>            
            <span>Delivery Date</span>
            </div>
        {          
          currentMilkingValue
          .map((item) =>(
            <div key={item.cowid} style={{padding:'10px', display:'flex', justifyContent:'space-evenly'}}>
            <span>{item.cowtag}</span>
            <span>{item.dailymilkingqty}</span>
            <span>{Math.round(item.dayssincedelivery*10/30)/10}</span>            
            <span>{item.lastdeliverydate.substring(0,10)}</span>
            </div>
           ) )
        }
        </div>}
    </Box>
    <Box>
    <Card variant="outlined" sx={{padding:'10px'}}>
      <CardContent>
        <Typography variant='h6' fontSize={16} fontWeight={'bold'}>Update Milking</Typography>              
      <hr/>
      <UpdateMilking selectlist={ AdultFemaleTagList } fs={selectedCow}/>
      </CardContent>
    </Card>
    </Box>
    <Box>
    {/* <Button aria-label='Clear All' variant='outlined' onClick={()=>{
      getMilkChartData(72)
    }} endIcon={<DeleteSweepIcon color='blue'/>}>
        Get data</Button> */}
       { (milkvalue.length) >0 && <Bar options={options} data={datamilkchart} sx={{        
        innerWidth:'80%',
        justifyContent: 'center'
      }} /> }
    </Box>
    <Box sx={{position:'relative', padding:'30px', backgroundColor:'white'}}>
    <Button aria-label='Clear All' variant='outlined' onClick={ClearAllMilking} endIcon={<DeleteSweepIcon color='blue'/>}>
        Clear All Milking</Button>
    </Box>
    </>
    </div>
  )
}

export default CurrentMilking