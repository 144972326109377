import React , {useState, useEffect} from 'react'
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from '@mui/material';

const SemenTypeRadioGroup = ({onSelectChange}) => {

const [value, setValue] = React.useState('Normal');

const handleChange = (event) => {
    setValue(event.target.value);
    // console.log(event.target.value);
    onSelectChange(event.target.value);
  };

  return (
    <FormControl
>
<FormLabel id="demo-form-control-label-placement">Semen Type</FormLabel>
  <RadioGroup
          row
          aria-labelledby="demo-form-control-label-placement"
          name="position"
          value={value}
          onChange={handleChange}
          sx={{
            border:1,
            borderRadius:5,
            paddingLeft:3,
            paddingRight:3,
            fontSize:'6px'
          }}
        >
          <FormControlLabel
          value="Normal"
          control={<Radio />}
          label="Normal"
          labelPlacement="start"
        />
        <FormControlLabel
          value="Female"
          control={<Radio />}
          label="Female"
          labelPlacement="start"
        />
    </RadioGroup>
</FormControl>
  )
}

export default SemenTypeRadioGroup