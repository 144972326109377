import React from 'react'
import { useContext, useState, useEffect } from 'react';
import DataContext from '../Context/DataContext';
import { useNavigate } from 'react-router-dom';
import BasicImageList from './BasicImageList';

const AdultFemaleImageList = () => {
    let navigate = useNavigate();
    const {isLoading, items } = useContext(DataContext);
    const [imagelist, setImageList] = useState([]);
    useEffect(()=>{
      if (!isLoading){
        setImageList(
        items.map(item =>{         
          const infolist = [
            `Days since last delivery ${item.dayssincelastdelivery}`,
            `Days since IV ${item.dayssinceiv===999?'NA': item.dayssinceiv}`,
            `Days since deworming ${item.dayssincedeworming ===999?'NA': item.dayssincedeworming}`
          ];
          return (
              [
                  item.cowid,
                  item.image1,
                  item.tag,
                  item.status,
                  item.isMilking,
                  item.isVerified,
                  item.status,
                  infolist
              ]
          );        
      }).filter(value => value[6] === 'Adult Female'));
      }
    },[isLoading]);
    // console.log(items);
        

  return (
    <BasicImageList title={"Adult Female Image List"} imglist={imagelist}  />
  )
}

export default AdultFemaleImageList