import {createContext, useState, useEffect} from 'react'
import axios from 'axios';

const DataContext = createContext();

export const DataProvider= (props) =>{
    const [items, setItems] = useState();
    const [listCowTypes, setlistCowTypes ] = useState([]);
    const listCowTypeLabel = ['Female Milking', 'Female Non Milking', 'Heifer (<1yr)', 'Heifer - Female', 'Others'];
    const [isLoading, setIsLoading] = useState(true);
    const [fetchError, setFetchError] = useState(null);
    const [isDirty, setIsDirty] = useState(0);
    const [updatedRem, setRemark] = useState({cowid : 0, remark:""});
    const [updateError, setUpdateError] = useState(null);
    const [requiredeworming, setRequireDeworming] = useState(0);
    const [allcows, setAllCows] = useState(0);
    const [awaitingAi, setAwaitingAi] = useState(0);
    const [awaitingAiList, setAwaitingAiList] = useState([]);    
    const [allAdultFemaleCows, setAllAdultFemaleCows] = useState(0);
    const [totalIsPregnant, setTotalIsPregnant] = useState(0);
    const [pregnancySuspect, setPregnancySuspect] = useState(0);
    const [listSuspectPregnancy, setSuspectPregnancyList ] = useState([]);
    const [listMilking, setMilkingList ] = useState([]);
    const [listApproachingDelivery, setApproachingDeliveryList ] = useState([]);
    const [AdultFemaleTagList, SetAdultFemaleTagList] =  useState([]);
    const [milkUpdateLoading, setMilkUpdateLoading] =  useState([]);
    const [missedAIList, setmissedAI] =  useState([]);
    const [totalMissedAI, setTotalMissedAI] =  useState(0);
    const [OtherCowsList, setOtherCows] =  useState([]);
    const [totalOtherCows, setTotalOtherCows] =  useState(0);
    const [ExpenseChartData, setExpenseChartData] = useState([]);
    const [sixMonthPregnantList, setsixMonthPregnant] =  useState([]);
    const [totalSixMonthPregnant, setTotalSixMonthPregnant] =  useState(0);
    const [eightMonthPregnantList, setEightMonthPregnant] =  useState([]);
    const [totalEightMonthPregnant, setTotalEightMonthPregnant] =  useState(0);

    const API_URL = 'https://sasyavatagro.com/repos/api/dairyfarm/GetBasicCowList';

    // console.log(API_URL);
//  const { listItems, fetchError, isLoading } = useAxiosFetch('https://sasyavatagro.com/repos/api/dairyfarm/GetBasicCowList');
  //    setTimeout(()=>fetchItems(), 200);
    useEffect(()=>{
        // console.log("getting data");
        // console.log("isdirty is "+isDirty);
        const fetchItems = async ()=>{
            try{
              const response = await fetch(API_URL);
              if (!response.ok) throw Error("Failed to fetch data");
              const all_list = await response.json();
              console.log(all_list);
            const listItems = all_list.filter(val => (val.status === 'Adult Female' || val.status === 'Heifer - Female'))
              setItems(listItems);
              let lst = [];
              lst.push(all_list.filter(val => (val.status === 'Adult Female' && val.isMilking === 1)).length);
              lst.push(all_list.filter(val => (val.status === 'Adult Female' && val.isMilking === 0)).length);
              lst.push(all_list.filter(val => (val.status === 'Adult Female' && val.isMilking === 1)).length);
              lst.push(all_list.filter(val => (val.status === 'Heifer - Female')).length);
              lst.push(all_list.filter(val => (val.status === 'Adult Female' && val.status === 'Heifer - Female')).length);

              setSuspectPregnancyList(all_list.filter(val => (val.isPregnant === 0  && val.dayssinceiv <= 270 
                &&  val.status === 'Adult Female'  && val.dayssincelastdelivery > val.dayssinceiv )));
              
              setPregnancySuspect(all_list.filter(val => (val.isPregnant === 0  && val.dayssinceiv <= 270 
                  &&  val.status === 'Adult Female' && val.dayssincelastdelivery > val.dayssinceiv )).length);

              setMilkingList(all_list.filter(val => (val.isMilking === 1  )).sort(function(a,b){
                return a.lastmilkingqty-b.lastmilkingqty;
              }));

              setOtherCows(all_list.filter(val => (val.status !== 'Adult Female' && val.status !== 'Heifer - Female')));
              setTotalOtherCows(all_list.filter(val => (val.status !== 'Adult Female' && val.status !== 'Heifer - Female')).length);

              SetAdultFemaleTagList(all_list.filter( x => (x.status === 'Adult Female')));

              setApproachingDeliveryList(all_list.filter(val => (val.dayssinceiv >= 240 && val.isPregnant === 1)));

              setRequireDeworming(all_list.filter(val => (val.dayssincedeworming > 90 )).length);

              setAwaitingAi(all_list.filter(val => (val.status === 'Adult Female' && ((val.dayssincelastdelivery > 40 &&
                  val.dayssinceiv > val.dayssincelastdelivery && val.dayssinceiv <90) || (val.dayssincelastdelivery > 40 &&
                    val.dayssinceheat < 90 && val.dayssinceiv > val.dayssinceheat)))).length);

              setAwaitingAiList(!all_list ? 0 : all_list.filter(val => (val.status === 'Adult Female' && ((val.dayssincelastdelivery > 40 &&
                  val.dayssinceiv > val.dayssincelastdelivery && val.dayssinceiv <90) || (val.dayssincelastdelivery > 40 &&
                    val.dayssinceheat < 90 && val.dayssinceiv > val.dayssinceheat)))));

              setmissedAI(all_list.filter(val => (
                val.status === 'Adult Female' && val.isPregnant === 0
                      && val.dayssinceiv > 270 && val.dayssinceiv !== 999
                      && (
                        (val.dayssinceiv < val.dayssinceheat && val.dayssinceheat < val.dayssincelastdelivery) ||
                        (val.dayssincelastdelivery > val.dayssinceiv && val.dayssinceheat > val.dayssincelastdelivery) || 
                        val.ifdate === '' || val.lastdeliverydate ===''
                      )
              )));            

              setTotalMissedAI(all_list.filter(val => (
                val.status === 'Adult Female' && val.isPregnant === 0
                      && val.dayssinceiv > 270 && val.dayssinceiv !== 999
                      && (
                        (val.dayssinceiv < val.dayssinceheat && val.dayssinceheat < val.dayssincelastdelivery) ||
                        (val.dayssincelastdelivery > val.dayssinceiv && val.dayssinceheat > val.dayssincelastdelivery) || 
                        val.ifdate === '' || val.lastdeliverydate ===''
                      )
              )).length);              
                      
              setAllAdultFemaleCows(all_list.filter(val => (val.status === 'Adult Female')).length);
              setTotalIsPregnant(all_list.filter(val => (val.isPregnant === 1 )).length);

              setTotalSixMonthPregnant(all_list.filter(val => (val.isPregnant === 1 && val.dayssinceiv > 183 
                && val.dayssinceiv <= 244)).length);

              setsixMonthPregnant(all_list.filter(val => (val.isPregnant === 1  && val.dayssinceiv > 183 
                && val.dayssinceiv <= 244 )));

              setTotalEightMonthPregnant(all_list.filter(val => (val.dayssinceiv < val.dayssincelastdelivery && val.isPregnant === 1 && 
                val.dayssinceiv > 244 && val.dayssinceiv <= 290)).length);
  
              setEightMonthPregnant(all_list.filter(val => (val.dayssinceiv < val.dayssincelastdelivery && val.isPregnant === 1  && 
                val.dayssinceiv > 244 && val.dayssinceiv <= 290 )));

              setAllCows(all_list.length);
              // console.log(lst);
              setlistCowTypes(lst);
              setFetchError(null);          
            }catch(err){
              setFetchError(err.message);
            } finally{
              setIsLoading(false);
            }
          }
    
          const GetExpenseTrendData = async () => {
            const API_URL = 'https://sasyavatagro.com/repos/api/dairyfarm/getexpensetrend';
        
            try{
              const response = await fetch(API_URL);
              if (!response.status) throw Error("Failed to fetch data");
              let chartdata = await response.json();
              setExpenseChartData(chartdata)
        
            //   return chartdata;
        
            }
            catch (err) {
              console.log(err);
              // ShowToast('danger', err + ' ' +'some error has happened');        
            }
            finally {
              // console.log("updatevalue " + updatevalue);
            }
          }
    
        GetExpenseTrendData();          
        fetchItems();
          // console.log("data context refreshed");
    
      },
      [isDirty])

  const InitiateRefresh=()=>{
      // console.log("setting is dirty");
      let temp = isDirty+1;
      // console.log("temp is " + temp);
      setIsDirty(temp);
    }


    useEffect( () =>  {

      const updateItems = async ()=>{

        if(updatedRem.cowid !== 0){
            try{
              // console.log("Updating " + JSON.stringify(updatedRem));
              const response = await axios.post(`/UpdateCowRemarks`, updatedRem);
              // console.log(response.status);
              // console.log(response.data);
              if (!response.ok) throw Error("Failed to fetch data");

            }
            catch {
              setUpdateError("Unable to update");
            }
            finally {
              setRemark("");
            }
        }
      }
      
      updateItems();
     
    }, [updatedRem]);


    const UpdateCowRemarks = async (cowid, remark) => {

      setIsLoading(true);

      const updatedRemarkData = {
          cowid: cowid, 
          remark: remark
       }
//              `https://www.sasyavatagro.com/repos/api/DairyFarm/UpdateCowRemarks`, 
      console.log("data " + JSON.stringify(updatedRemarkData))
       try{
          const response = await axios.post(
              `https://www.sasyavatagro.com/repos/api/DairyFarm/UpdateCowRemarks`, 
              updatedRemarkData);
            console.log(JSON.stringify(response));
            InitiateRefresh();
          }
      catch (err){
        setUpdateError(err.message);
      } finally {
        setIsLoading(false);
      }
  }

  const GetCowMilkTrendData = async (id) => {
    const API_URL = 'https://sasyavatagro.com/repos/api/dairyfarm/GetCowMilkTrendData/' + id;

    try{
      const response = await fetch(API_URL);
      if (!response.status) throw Error("Failed to fetch data");
      let chartdata = await response.json();

      return chartdata;

    }
    catch (err) {
      console.log(err);
      // ShowToast('danger', err + ' ' +'some error has happened');        
    }
    finally {
      // console.log("updatevalue " + updatevalue);
    }

  }

  const ClearAllMilking = ()=>{
    const API_URL = 'https://sasyavatagro.com/repos/api/dairyfarm/ClearMilking';
    const updatemilk= async ()=>{
  
      try{
        setMilkUpdateLoading(1);
        const response = await axios.get(API_URL);  
  
          console.log(response.data);
      }
      catch(err){
        console.log(err);
      }
      finally{
        InitiateRefresh();
        setMilkUpdateLoading(0);        
      }
    };
        updatemilk();      
  }

  // console.log(missedAIList);

    return (
        <DataContext.Provider value={{
            items, fetchError, isLoading,            
            isDirty, InitiateRefresh, GetCowMilkTrendData, ExpenseChartData,
            listCowTypes, listCowTypeLabel, listSuspectPregnancy, listApproachingDelivery,
            UpdateCowRemarks, updateError, totalIsPregnant, pregnancySuspect,awaitingAi, awaitingAiList, 
            requiredeworming, allcows, allAdultFemaleCows, listMilking, AdultFemaleTagList,
            milkUpdateLoading, ClearAllMilking, missedAIList, totalMissedAI, OtherCowsList, totalOtherCows,
            sixMonthPregnantList, totalSixMonthPregnant, eightMonthPregnantList, totalEightMonthPregnant        
        }}>
            { props.children }
        </DataContext.Provider>
    )

}


export default DataContext