import React from 'react'
import { useContext, useState, useEffect } from 'react';
import DataContext from '../Context/DataContext';
import { useNavigate } from 'react-router-dom';
import BasicImageList from './BasicImageList';
import ProgressIndicator from '../Indicator/ProgressIndicator';

const EightMonthsPregnantList = () => {
    const { isLoading,  eightMonthPregnantList } = useContext(DataContext);
    const [imagelist, setImageList] = useState(eightMonthPregnantList);
    // console.log(eightMonthPregnantList);

    useEffect(()=>{
        if (!isLoading){
            // console.log(eightMonthPregnantList);
            setImageList(
            imagelist.map(item =>{    
                const infolist = [
                  `Days to Delivery: ${275 - item.dayssinceiv}`,
                  `Days since deworming: ${item.dayssincedeworming ===999?'NA': item.dayssincedeworming}`
                ];     
                  return (
                      [
                          item.cowid,
                          item.image1,
                          item.tag,
                          item.status,
                          item.isPregnant,
                          item.dayssincelastdelivery,
                          item.dayssinceiv,
                          infolist
                      ]
                  );        
              })
          );
        }
      },[isLoading, eightMonthPregnantList]);

  return (
    <>
     {isLoading && <ProgressIndicator /> }
    {    !isLoading && <BasicImageList title={"Eight Months Pregnant"} imglist={imagelist}  />}
    </>
  )

}

export default EightMonthsPregnantList