import React, { useEffect, useState } from 'react'
import { useNavigate  } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Title from '../Title/Title'
import useAxiosFetch from '../Hooks/useAxiosFetch';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ProgressIndicator from '../Indicator/ProgressIndicator';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: '#bbe2fc',
//     color: theme.palette.common.black,
//     align: 'left'
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14
//   },
// }));

const BirthingSchedule = () => {
const navigate = useNavigate();
const {data, fetchError, isLoading} = useAxiosFetch("https://sasyavatagro.com/repos/api/dairyfarm/GetBirthingSchedule");
const [dataItems, setDataItems]= useState([]);

useEffect(()=>{
  try{
    // console.log(isLoading);
    if(data.status===200){
      setDataItems(data.data)
    }
  } catch (err){
    // console.log(fetchError)
  } finally{
    // console.log(data["data"] );
  }

},[isLoading]);

  return (
    <>
    <Title title={"Calving Forecast"} />
    {isLoading && <ProgressIndicator />}
    {!isLoading && 
      <Table stickyHeader sx={{ maxWidth: '100%' }} aria-label="customized table">
        <TableHead>
          <TableRow stickyHeader>
          <TableCell align="center" sx={{backgroundColor:"#bbe2fc", fontWeight:600}}>Year Month</TableCell>
            <TableCell align="left" sx={{backgroundColor:"#bbe2fc", fontWeight:600}}>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          dataItems.map((item) => (
            <TableRow onClick={()=>{
                navigate('/birthingIVList/'+ item.yearmonth, { replace: false })
              }              
            } sx={{
              '&:nth-of-type(odd)': {
                backgroundColor:"#e8f3fa"
              }              
            }} >
            <TableCell align="center">{item.yearmonth}</TableCell>
            <TableCell align="left">{item.count}</TableCell>
            </TableRow>
          ))
          }
        </TableBody>
      </Table>
    }
    </>
  )
}

export default BirthingSchedule