import React, {useState, useEffect} from 'react'
// import dayjs from 'dayjs';
import { format } from 'date-fns'
// import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { registerLocale } from  "react-datepicker";
// import es from 'date-fns/locale/en-in';
import dayjs from 'dayjs';

// registerLocale('es', es)

// const GetDate = () => {
  const GetDate = ({fs}) => {

  const x = format(new Date(), 'yyyy-MM-dd');
  const [value, setValue] = useState(x);
  const[maxvalue, setMaxValue]=useState(x);
  const [formatedvalue, setFormatedValue] = useState();
  // console.log(value);
    
    const handleOnChange = (newValue) => {
      // console.log("in date " + newValue);
      // setStartDate(newValue);
      setValue(newValue);
      // onDateChange(newValue);
   
      // onChange(newValue);
    };

    const onDateChange=(event)=>{
      console.log(event.target.value);
      setValue(event.target.value);               
      // fs(event.target.value);
    }

    useEffect(()=>{
      fs(value);
    },[value], fs)

    useEffect(()=>{
      if(value){
        let yr = value.substring(0,4);
        let mth = value.substring(5,7);
        let dy = value.substring(8,10);
        // console.log("formated date " + dy);
        setFormatedValue(`${dy}-${mth}-${yr}`)
     }
    },[value])


    return (    
    <>
       <input type="date" 
       value={value}
       max={maxvalue}
       onChange={onDateChange} />
       <h5>Selected Date: {formatedvalue} </h5>
    </>
  );
}

export default GetDate

    // const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
    // const [value, setValue] = dayjs(new Date());
    // const [startDate, setStartDate] = useState(new Date());
//format(dayjs(value), 'dd/MM/yyyy')