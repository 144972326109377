import React from 'react'
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
// import { Padding } from '@mui/icons-material';

const HomeStats = ({txt, val, fs, bgclr, circlr}) => {
    // console.log(txt);
    // console.log(val);
    // console.log(bgclr);
    // console.log(circlr);
  return (
    <Box sx={{margin:"0px", cursor:"pointer"}} onClick={fs}>
    <Card variant="outlined"  sx={{
      display:'flex',
      flexDirection:'column',
        backgroundColor: bgclr ,
        justifyContent: 'stretch',
        height:'80px',
        width:'80px'
      }}>
      <CardContent sx={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
          backgroundColor: bgclr ,
          alignContent: 'space-between',
      }}>
        <Typography variant='primary' sx={{
          fontSize:8,
          fontWeight:800,
        }}>{txt}</Typography>
          <Box sx={{
            width: 30,
            height: 30,
            borderRadius: 10,            
            lineHeight: 1.5,
            margin:0,
            backgroundColor:circlr,
            alignSelf:"center",
            marginLeft:"auto",
            marginRight:"auto",
            marginBottom:0,
            marginTop:1,
            boxShadow:5
          }}>
          <Typography variant='primary' sx={{
          fontSize:10,
          fontWeight:800,
        }}>{val}</Typography>
          </Box>
      </CardContent>        
      {/* <CardActions sx={{backgroundColor:"#f0f6fa"}}>
          <Button size="small" onClick={fs}>View...</Button>
      </CardActions> */}
    </Card></Box>
  )
}

export default HomeStats