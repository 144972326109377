import React from 'react'
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { Typography } from '@mui/material';

const HistoryTitleAvatar = ({cowtag}) => {
  return (
    <div style={{paddingLeft:'10px', paddingRight:'10px', position:'relative', top:'10px' }}>
                      <Avatar sx={{ bgcolor: deepPurple[500], width: 36, height: 36 }}>
                        <Typography sx={{fontSize:'12px'}}>{cowtag}</Typography>
                      </Avatar>
                    </div>
  );
}

export default HistoryTitleAvatar