import React from 'react'
import { useContext, useState, useEffect } from 'react';
import DataContext from '../Context/DataContext';
import { useNavigate } from 'react-router-dom';
import BasicImageList from './BasicImageList';
import ProgressIndicator from '../Indicator/ProgressIndicator';

const SuspectPregnancy = () => {
    const { isLoading, listSuspectPregnancy } = useContext(DataContext);
    const [imagelist, setImageList] = useState(listSuspectPregnancy);

    // console.log(listSuspectPregnancy);
    
    // let navigate = useNavigate();
    useEffect(()=>{
      if (!isLoading){
        setImageList(
            listSuspectPregnancy.map(item =>{    
              const infolist = [
                `Days since last delivery: ${item.dayssincelastdelivery}`,
                `Days since IV: ${item.dayssinceiv===999?'NA': item.dayssinceiv}`,
                `Days since deworming: ${item.dayssincedeworming ===999?'NA': item.dayssincedeworming}`
              ];     
                return (
                    [
                        item.cowid,
                        item.image1,
                        item.tag,
                        item.status,
                        item.isPregnant,
                        item.dayssincelastdelivery,
                        item.dayssinceiv,
                        infolist
                    ]
                );        
            })
        );
      }
    },[isLoading, listSuspectPregnancy]);
    // console.log(items);

  return (
    <>
     {isLoading && <ProgressIndicator /> }
    {    !isLoading && <BasicImageList title={"Pregnancy Suspect"} imglist={imagelist}  />}
    </>
  )
}

export default SuspectPregnancy