import './Cowlist.css';
import { useState, useContext, useEffect } from 'react';
import DataContext from '../Context/DataContext';
import CowCardItem from './CowCardItem';
import ProgressIndicator from '../Indicator/ProgressIndicator';
import Title from '../Title/Title';
// import Api from '../GetData/Api';
import axios from 'axios';
import YesNoAlert from '../Dialog/YesNoAlert';
import YesNoToast from '../Toast/YesNoToast';
import { TOAST_PROPERTIES } from '../Toast/ToastProperties';

const Cowlist = () => {
  
  const { items, fetchError, isLoading, InitiateRefresh } = useContext(DataContext);
  const [toastList, setToastList]=useState([]);
  const [toastMsg, SetToastmsg]=useState('');
  const [yesNoToastValue, SetyesNoToastValue] = useState('');
  const [affectedCowId, SetaffectedCowId] = useState(0);
  const [isPregnant, setPregnancyValue]=useState(false);

    // console.log(items);
    const UpdatePregnancyStatus = async (cowid, currentPregnant) =>  {
      // let a = YesNoQuestion("Do you want to update");      
      ShowYesNoToast("warning", "Are you sure you want to update Pregnancy status", cowid);
      SetaffectedCowId(cowid);
      setPregnancyValue(currentPregnant);
      // console.log("radio value " + cowid);
      return;
      // console.log('cowid: ' + cowid);
      // console.log('isPregnant ' + isPregnant);      
  }

  useEffect(()=> {
    // console.log(`New value ${ yesNoToastValue } of affected cow id ${ affectedCowId }`);

    async function updatepregstatus(){
      if (affectedCowId !== 0 && affectedCowId !== "" && yesNoToastValue !=="" && isPregnant !== yesNoToastValue){
        // isPregnant = isPregnant=== 0 ? 1 : 0;
          const upddata = { cowid: affectedCowId, isPregnant: yesNoToastValue };
          // console.log (JSON.stringify(upddata));          
          try{
            // setPregnancyLoadingValue(true);
            // const response = await Api.post('UpdatePregnantStatus', data);
            const response = await axios.post(
              `https://www.sasyavatagro.com/repos/api/DairyFarm/UpdatePregnantStatus`, 
              upddata);
            // console.log(JSON.stringify(response));
              // const response = await fetch(API_URL);            
            console.log (JSON.stringify(response));
            if (response.status !== 200) throw Error("Failed to fetch data");
            //  console.log("response data " + response.data);
            // setopenSnack(true);
            InitiateRefresh();
          } catch (err){
            console.log(`Error: ${err.message}`);        
          } finally {
            SetaffectedCowId("");
            // setPregnancyLoadingValue(false);             
          }
        }
    }
    updatepregstatus();

  }, [yesNoToastValue])
  

    const handleYesNoToastClose=(value, currentPregnancyStatus)=>{
      SetyesNoToastValue(value);
      setPregnancyValue(currentPregnancyStatus);
      // console.log(`Value ${value}`);
    }

    const ShowYesNoToast = (type, msg) => {
      const list = TOAST_PROPERTIES.find((toast) => toast.title.toLowerCase() === type);
        SetToastmsg(msg);
        setToastList([...toastList, list]);
    };

    // const deleteToast = id => {
      // const index = list.findIndex(e => e.id === id);
      // list.splice(index, 1);
      // setToastList([]);
  // }  

  return (
    <>
      <Title title={"Cow List"} />
      <div style={{margin:'10px'}}>
        <YesNoToast               
          toastList={toastList}
          position="bottom-center"
          message={toastMsg}
          onClose={handleYesNoToastClose}
          />
          </div>
      {/* {isPregnancyLoading && 
      <ProgressIndicator></ProgressIndicator>} */}
      {isLoading && 
      <ProgressIndicator></ProgressIndicator>}
      {fetchError && <p style={{ color: "red" }}>{`Error: ${fetchError}`}</p>}
      {!fetchError && !isLoading && 
        items.length ? (
          <div className='AllCowList'>
            <ul ></ul>
                {
                    items.map((item)=>(
                        <CowCardItem 
                        key={item.cowid}
                        item={item}
                        fs={UpdatePregnancyStatus}
                        />
                      )
                    )
                }
            </div>
        ) : (
          !isLoading && <p style={{ marginTop: '2rem' }}>Your list is empty.</p>
        )
        }
    </>
  )
}

export default Cowlist

