import React, {useState, useEffect} from 'react'
import Title from '../Title/Title.js'
import { useParams } from 'react-router-dom';
import ProgressIndicator from '../Indicator/ProgressIndicator.js';
import DeliveryDateContent from './DeliveryDateContent.js';
import VerifiedTagContent from './VerifiedTagContent.js';
import UpdateTagContent from './UpdateTagContent.js';
import DewormingDateContent from './DewormingDateContent.js';
import IVDateContent from './IVDateContent.js';
import MilkingUpdateContent from './MilkingUpdateContent.js';
import MilkValueContent from './MilkValueContent.js';
import StatusContent from './StatusContent.js';
import RemarksTagContent from './RemarksTagContent.js';
import HeatDateContent from './HeatDateContent.js';
import PregnancyStatusTag from './PregnancyStatusTag.js';
import HistoryTitleAvatar from './HistoryTitleAvatar.js';
import UpdateMilkContent from './UpdateMilkContent.js';

const CowHistory = () => {
    const [historyItems, setHistoryItems] = useState([]);
    const [isLoading, setLoadingValue]=useState(false);
    const [fetchError, setFetchError] = useState(null);
    const routeParams = useParams();
    // console.log(routeParams.cowid);

    useEffect(()=> { 
        const API_URL = 'https://sasyavatagro.com/repos/api/dairyfarm/GetHistory/' + routeParams.cowid;
        // console.log(API_URL);
        const fetchItems = async ()=>{
          setLoadingValue(true);
          try{
            const response = await fetch(API_URL);
            if (!response.status) throw Error("Failed to fetch data");
            const listItems = await response.json();
            // console.log(listItems);
            setHistoryItems(listItems);
            setFetchError(null);                   
        }
          catch (err){
            setFetchError("No History data found");
            setHistoryItems([]);
          }
          finally{
            setFetchError("");
            setLoadingValue(false);
          }
        }
        fetchItems();  
    }, [routeParams.cowid])


    return (    
    <>
    <Title title={"Cow History"} />
    {isLoading && <ProgressIndicator />}
    {/* {(!isLoading & fetchError) && <div>No History data found</div>}     */}
    {(!isLoading & !fetchError) && 
        <div className="chMain" style={{display:'flex', flexDirection:'row'}}>                    
            <ul>
                {
                historyItems.map((item, index)=>(            
                <li key={ index }>
                  <div style={{display:'flex', flexDirection:'row'}}>
                  <HistoryTitleAvatar cowtag={item.CowTag} />
                    <div>
                    {/* {format(new Date(item.CreateDate), "dd MMM yyyy")} <br /> */}
                    {item.Tag === "lastdeliverydate" ? 
                        <DeliveryDateContent item={item} /> : 
                            item.Tag === "VerifiedTag" ? 
                        <VerifiedTagContent item={item} /> :
                          item.Tag === "Update Tag" ? 
                        <UpdateTagContent item={item} /> :
                            item.Tag === "dewormingdate" ? 
                        <DewormingDateContent item={item} /> :
                            item.Tag === "ivdate" ? 
                        <IVDateContent item={item} /> :
                            item.Tag === "MilkingUpdate"  || item.Tag === "MilkingEnd" ? 
                        <MilkingUpdateContent item={item} /> :
                            item.Tag === "Milk Value"  ? 
                        <MilkValueContent item={item} /> :
                          item.Tag === "Status"  ? 
                        <StatusContent item={item} /> :
                          item.Tag === "Remarks"  ? 
                        <RemarksTagContent item={item}/> : 
                          item.Tag === "heatdate"  ? 
                        <HeatDateContent item={item} /> :
                          item.Tag === "pregnancystatus"  ? 
                        <PregnancyStatusTag item={item}/> : 
                        item.Tag === "Update Milk"  ? 
                        <MilkValueContent item={item}/> : item.Tag }
                    </div>
                  </div> 
                </li>
                )
                    )                    
                }
            </ul>    
    </div>
    }
    </>
  )
}

export default CowHistory