import React from 'react'
import { useContext, useState, useEffect } from 'react';
import DataContext from '../Context/DataContext';
import { useNavigate } from 'react-router-dom';
import BasicImageList from './BasicImageList';
import ProgressIndicator from '../Indicator/ProgressIndicator';

const LongMissingAI = () => {
    const { isLoading,  missedAIList } = useContext(DataContext);
    const [imagelist, setImageList] = useState(missedAIList);
    // console.log(missedAIList);

    useEffect(()=>{
        if (!isLoading){
          setImageList(            
            missedAIList.map(item =>{    
              const infoitem = item.dayssinceiv < item.dayssinceheat ? `Days since IV ${item.dayssinceiv}` :
            `Days since Heat ${ item.dayssinceheat}`;
                const infolist = [
                  `Days since deworming: ${item.dayssincedeworming ===999?'NA': item.dayssincedeworming}`,
                  infoitem
                ];     
                  return (
                      [
                          item.cowid,
                          item.image1,
                          item.tag,
                          item.status,
                          item.isPregnant,
                          item.dayssincelastdelivery,
                          item.dayssinceiv,
                          infolist
                      ]
                  );        
              })
          );
        }
      },[isLoading, missedAIList]);

  return (
    <>
     {isLoading && <ProgressIndicator /> }
    {    !isLoading && <BasicImageList title={"Long Awaiting AI"} imglist={imagelist}  />}
    </>
  )
}

export default LongMissingAI