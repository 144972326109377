import { Typography } from '@mui/material'
import { useNavigate  } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import HouseSharpIcon from '@mui/icons-material/HouseSharp';
import './Title.css'
import BackButton from '../BackButton/BackButton';

const Title = ({title}) => {
  let navigate = useNavigate();
  return (
    <div className="Title">
      {/* <IconButton
      onClick={
        ()=>{
            navigate(-1);
            // console.log("Hello");
        }
    }>
    
  <WestSharpIcon/>
      </IconButton> */}
      <BackButton />
      <Typography variant='body2' sx={{
        fontFamily:"Roboto",
        fontSize:"16px",
        color:'blue',
        fontWeight:"bold"
      }} >{title}</Typography>
      {/* <Button onClick={() => navigate("/", { replace: true })}>Back</Button> */}
      
      <IconButton
                // sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`Home`}
                onClick={
                    ()=>{
                      // navigate(-1)
                        navigate("/", { replace: true })
                        // console.log("Hello");
                    }
                }                
              >
                <HouseSharpIcon style={{ color: "blue" }} />
      </IconButton>
      </div>
  )
}

export default Title