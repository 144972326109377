import React, {useContext} from 'react'
import './index.css'
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DataContext from './Components/Context/DataContext';
import HomeStats from './Components/Charts/HomeStats';
import ProgressIndicator from './Components/Indicator/ProgressIndicator';
import { Box } from '@mui/material';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

const HomePage = () => {
  const { requiredeworming, listCowTypes, allcows, awaitingAi, allAdultFemaleCows, totalSixMonthPregnant, isLoading,
      totalIsPregnant, pregnancySuspect, totalMissedAI, totalOtherCows, totalEightMonthPregnant } = useContext(DataContext);

      // console.log(isLoading);

  let navigate = useNavigate();
  return (
    <Grid container spacing={2} sx={{
      paddingLeft:'10px',
      paddingRight:'20px'
    }}>
      <Grid item xs={12} sx={{display:"flex", justifyContent:"center", width:"100vw"}}>
        <div className='ChartContainerCowType'> 
          <img src='https://sasyavatagro.com/img/cow/dairy_2_1.png'  
          width={'100%'}
          alt=''
          />   
        </div> 
      </Grid>
      {/* listCowTypes || !allcows || !allAdultFemaleCows || !requiredeworming || !awaitingAi */}
      { isLoading===true ?   
      <Grid item xs={12} >
          <ProgressIndicator /> </Grid>:      
      <>
      <Grid item xs={3} >
        <HomeStats txt={"All Cows"} val={allcows} fs={()=>{ navigate("/cowlist", { replace: false }); }} bgclr={"#f7ffd1"} circlr={'#c5f0fa'}/>
      </Grid>
      <Grid item xs={3}>
      <HomeStats txt={"Adult Female"} val={allAdultFemaleCows} fs={()=>{ navigate("/AdultFemaleImageList", { replace: false }); }} bgclr={"#cafcdc"} circlr={'#fa91e0'}/>
      </Grid>
      <Grid item xs={3}>
      <HomeStats txt={"Non Milking"} val={listCowTypes[1]} fs={()=>{ navigate("/AdultNonMilking", { replace: false }); }} bgclr={"#cafca4"} circlr={'#faa89d'}/>
      </Grid>
      <Grid item xs={3}>
      <HomeStats txt={"Heifers"} val={listCowTypes[3]} fs={()=>{ navigate("/HeiferImageList", { replace: false }); }} bgclr={"#cfdaff"} circlr={'#faf6c5'}/>
      </Grid>
      <Grid item xs={3}>
      <HomeStats txt={"Awaiting AI"} val={awaitingAi ? awaitingAi : 0} fs={()=>{ navigate("/AwaitingIVImageList", { replace: false }); }} bgclr={"#a3a8ff"} circlr={'#faf6c5'}/>
      </Grid>
      <Grid item xs={3}>
      <HomeStats txt={"Missed AI"} val={totalMissedAI} fs={()=>{ navigate("/LongMissedAI", { replace: false }); }} bgclr={"#85bb65"} circlr={'#faf6c5'}/>
      </Grid>      
      <Grid item xs={3}>
      <HomeStats txt={"Deworming ?"} val={requiredeworming} fs={()=>{ navigate("/DewormingImageList", { replace: false }); }} bgclr={"#fad9f9"} circlr={'#cafcdc'}/>
      </Grid>      
      <Grid item xs={3}>
      <HomeStats txt={"Other Cows"} val={totalOtherCows} fs={()=>{ navigate("/OtherCows", { replace: false }); }} bgclr={"#f7ffd1"} circlr={'#a3a8ff'}/>
      </Grid>      
      <Grid item xs={3}>
      <HomeStats txt={"Pregnancy Confirmed"} val={totalIsPregnant} fs={()=>{ navigate("/ConfirmPregnancy", { replace: false }); }} bgclr={"#c0c0c0"} circlr={'#faa89d'}/>
      </Grid>
      <Grid item xs={3}>
      <HomeStats txt={"Pregnancy to check"} val={pregnancySuspect} fs={()=>{ navigate("/SuspectPregnancy", { replace: false }); }} bgclr={"#87cefa"} circlr={'#faf6c5'}/>
      </Grid>
      <Grid item xs={3}>
      <HomeStats txt={'In Sixth Months'} val={totalSixMonthPregnant} fs={()=>{ navigate("/SixMonthsPregnant", { replace: false }); }} bgclr={"#deb887"} circlr={'#faa89d'}/>
      </Grid>
      <Grid item xs={3}>
      <HomeStats txt={"Eighth Months"} val={totalEightMonthPregnant} fs={()=>{ navigate("/EightMonthsPregnant", { replace: false }); }} bgclr={"#b2ec5d"} circlr={'#faa89d'}/>
      </Grid>
      <Grid item xs={12} sx={{ display:"flex", justifyContent:'center' }}>
        <Box display="flex" justifyContent='center' sx={{backgroundColor:'#2471A3', 
        paddingLeft:'40px', paddingRight:'40px', borderRadius:'20px', width:'200px' }}>
            <Button variant='primary' 
            sx={{
              color:'white',
              backgroundColor:'#2471A3'
            }} 
              onClick={()=>{ navigate("/cowdetail/0", { replace: false }); }}>Add New</Button>
        </Box>
      </Grid>
      </>
      }
      {/* <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}}>
      <Item><HomePageImageLinks /></Item>
      </Grid>         */}      
    </Grid>        
  )
}

export default HomePage