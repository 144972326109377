import React, { useEffect, useState } from 'react'
import useAxiosFetch from '../Hooks/useAxiosFetch';
import ProgressIndicator from '../Indicator/ProgressIndicator';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );


const ProductionTrend = () => {
const {data, fetchError, isLoading} = useAxiosFetch("https://sasyavatagro.com/repos/api/dairyfarm/GetMilkTrendData");
const [dataItems, setDataItems]= useState([]);
const [dataLabels, setDataLabels]= useState([]);
// const {chartDataLoaded, setChartData} = useState(0);

useEffect(()=>{

    // console.log("data: " +  data);
    try{
        
        if(data.status===200){
            let items = data.data;
            // console.log(items[0]);
            // var result = Object.keys(items).map((key)=>[Number(key), items[key]]);      
            var a = items.map(item => {
              var x = Object.values(item);
              return(
                x[1]
            );
            });
            
            setDataItems(a);

            var b = items.map(item => {
              var x = Object.values(item);
              var xstr = String(x[0]).substr(4, 4)
              return(
                xstr
              );
            });
            
            // console.log(b);

            setDataLabels(b)
            // setChartData(1);
        }
  } catch (err){
    console.log(err);
    console.log(fetchError)
  } finally{
    // console.log(data.data)
    // console.log("data items: " + dataItems);
    // console.log("data labels: " + dataLabels);
  }

},[isLoading]);

// useState(()=>{
//   //reload
  
// }, [chartDataLoaded])

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Milk Production',
    },
  },
  scales: {
    y: {
      min: 40,      
        ticks: {
         stepSize: 1, 
        }      
      }
  }
};

const chartdata = {
    labels: dataLabels,
    datasets: [
      {
        label: 'Qty',
        data: dataItems,
        borderColor: '#0f03fc',
        backgroundColor: '#eae8fa',
        borderWidth: 1,
      },
    ],
  };


return (
<>
   {dataItems.length>0 ?  <Line options={options} data={chartdata} sx={{        
        innerWidth:'80%',
        justifyContent: 'center'
    }} /> : 
    <ProgressIndicator />}
</>
    );
}

export default ProductionTrend