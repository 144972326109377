import React, {useState, useEffect, useContext} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Grid, Box, TextField } from '@mui/material';
import Title from '../Title/Title';
import axios from 'axios';
import './CowDetailUpdateMilk.css'
import '../Indicator/ProgressIndicator'
import ProgressIndicator from '../Indicator/ProgressIndicator';
import DataContext from '../Context/DataContext';

const CowDetailUpdateMilk = ({cowid}) => {
  const { InitiateRefresh } = useContext(DataContext);  
const navigate = useNavigate();

const [baseval, setBaseVal] = useState(0);
const [milkvalue, setMilkValue] = useState(0);
const [milkqty, setMilkQty] = useState(0);
const [isloading, setLoading] = useState(0);
const [cow_id, setCowId] = useState(0);
const [cowtag, setCowTag] = useState(0);

const routeParams = useParams();
const qsArray = routeParams.cowid.split(",");
//console.log("param data id" + qsArray[0]);
//console.log("param data tag" + qsArray[1]);


const UpdateMilkValue = ()=>{
  setMilkQty(milkvalue-baseval);
    // console.log(` ${ routeParams.cowid } hello ${milkvalue - baseval}`);
}

useEffect(()=>{
  setCowId(qsArray[0]);
setCowTag(qsArray[1]);
},[]);

useEffect(()=>{
  const API_URL = 'https://sasyavatagro.com/repos/api/dairyfarm/UpdateCowMilk';
  const updatemilk= async ()=>{
    const updatedMilkPost = 
    { 
      cowid: routeParams.cowid, 
      milkvalue: milkqty 
    };
     console.log(JSON.stringify(updatedMilkPost));

    try{
      setLoading(1);

//      const response = await axios.post(
//        API_URL, 
//        updatedMilkPost);  

      var url = `https://www.sasyavatagro.com/repos/api/DairyFarm/UpdateCowMilk`;
//        var url = `https://localhost:44383/repos/api/DairyFarm/UpdateCowMilk`;
        url = url + "?cowid=" + cow_id;
        url = url + "&milkvalue=" + milkqty;
    
        const response = await fetch(url, 
          {
            method: 'GET'
          }
      );

        console.log(response.data);
    }
    catch(err){
      console.log(err);
    }
    finally{
      InitiateRefresh();
      setBaseVal(0);
      setMilkValue(0);
      setLoading(0);
      navigate(-1);
    }
  };

    if(milkqty>0){
      updatemilk();
    }
  },[milkqty]);

  const ClearMilking = ()=>{
    alert('Hello');
  }

  return (
    <>
    { isloading ? <ProgressIndicator /> : <></> }
    { !isloading  && 
    <>
    <Title title={"Update MIlk"}/> 
<label>Update milk for {cowtag}</label>
    <div className='CowDetailUpdateMilkMain'>
    <Grid container spacing={0}>
      <Grid item xs={12} className='CowDetailClearMilkRow' textAlign={'left'} alignContent='end' >
        <Button 
        sx={{
          width:'10px',
          height:'30px'
        }}
        variant='contained'
        value='x'
        onClick={ClearMilking}
        >Clr</Button>
      </Grid>
    <Grid item xs={6} className='CowDetailUpdateMilkRow' textAlign="left">
    <TextField id="basicqty" label="Base Quantity" size='small' type='number'
    value={baseval}
    onChange={(e)=>{
        setBaseVal(e.target.value);
    }}
    variant="outlined" />
    </Grid>
    <Grid item xs={6}  className='CowDetailUpdateMilkRow' textAlign="left">
    <TextField id="milkqty" label="Milk Quantity" size='small' type='number'
    value={milkvalue}
    onChange={(e)=>{
        setMilkValue(e.target.value);
    }}
    variant="outlined" />
    </Grid>
    <Grid item xs={12} className='CowDetailUpdateMilkRow' textAlign="left">
        <Box sx={{display:'flex', justifyContent:'space-evenly'}}>
        <Button variant='contained' color='warning' onClick={() => navigate(-1)}>Cancel</Button>                                
                {/* <button type="submit">Update</button> */}
                <Button type="submit" variant='contained' onClick={UpdateMilkValue} >Update</Button>            
                {/* <button onClick={() => navigate(-1)}>Cancel</button> */}
            </Box>
            </Grid>
    </Grid>
    </div>              
    </>
}
    </>
  )
}

export default CowDetailUpdateMilk