import React, {useState, useEffect} from 'react'
import { Grid, Typography, Paper, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import '../Dialog/OptionsDialog';

const PaperItem = styled(Paper)(
    ({theme}) => (
      {
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        margin:"10px",
        maxWidth: "746px"
      }
    )
  );

  const handleDialogOpen = (_title, _type, _updtype) => {
    // setTitle(_title);
    // setType(_type);
    // setContent(_content);
    // setOpenDialog(true);
    // setUpdateType(_updtype);
  };

export const CowDetailTextItem = ({val, title}) => {
    return (
        <PaperItem>
        <Grid container spacing={0}>
          <Grid item xs={6}  textAlign="left">                
            <Typography variant="subtitle2" gutterBottom  sx={{
              fontWeight:"600"
            }}>
             {title}: { val }                                     
             <EditIcon  color='primary' onClick={ ()=>{
                    handleDialogOpen(`Update ${title}`, 
                    "text", 
                    "Enter yourTag below",
                    3)
                }              
        }></EditIcon>
        </Typography>             
        </Grid>              
        </Grid>
      </PaperItem>     
      )
}
