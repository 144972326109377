
import React, { useState, useEffect } from 'react';
import CowImageItem from './CowImageItem';


const DetailImageList = ( {items} ) => {
  const [images, setImages] = useState([]);
//   const API_ENDPOINT_URL = "https://sasyavatagro.com/repos/api/dairyfarm/GetBasicCowList";
//   const API_ENDPOINT_URL='https://sasyavatagro.com/repos/api/dairyfarm/DetailbyId?cowid=1';
  
//   useEffect(() => {
//     // Fetch images data from the API endpoint
//     fetch(API_ENDPOINT_URL)
//       .then(response => response.json())
//       .then(data => setImages(data))
//       .then(console.log(response))
//       .catch(error => console.error('Error fetching images:', error));
//   }, []);
// console.log(items);
// console.log(items['cowid']);

  const handleImageUpload = (id, newImageFile) => {
    // Handle the image upload here and update the API with the new image
    // You will need to implement the API call to update the image for the given ID
    // Example: 
    // fetch(`API_ENDPOINT_URL/${id}`, {
    //   method: 'PUT',
    //   body: formData // FormData containing the new image
    // })
    // .then(response => response.json())
    // .then(data => console.log('Image updated successfully:', data))
    // .catch(error => console.error('Error updating image:', error));
  };

  return (
    <>
    <div className="grid-container">
    <div className="grid-item">
<CowImageItem cowid = {items["cowid"]} imagename= {items["image1"]} keyno={"img1"}/>
    </div>
    <div className="grid-item">
    <CowImageItem cowid = {items["cowid"]} imagename= {items["image2"]} keyno={"img2"}/>

    </div>
    <div className="grid-item">
    <CowImageItem cowid = {items["cowid"]} imagename= {items["image3"]} keyno={"img3"}/>      
    </div>
    <div className="grid-item">
    <CowImageItem cowid = {items["cowid"]} imagename= {items["image4"]} keyno={"img4"}/>      
    </div>
    <div className="grid-item">
    <CowImageItem cowid = {items["cowid"]} imagename= {items["image5"]} keyno={"img5"}/>      
    </div>
    <div className="grid-item">
    <CowImageItem cowid = {items["cowid"]} imagename= {items["image6"]} keyno={"img6"}/>      
    </div>
    {/* <div className="grid-item">
    <div key={items.image2}
        sx={{ alignContent:"center", marginLeft:"auto", marginRight:"auto", width:"40vw"}}
        >
          <img
            src={items["image2"] !== "none" ? 
            "https://sasyavatagro.com/img/farmimg/" + items["image2"] + '?fit=fill&auto=format' :
            "../../images/cow_img.png?fit=fill&auto=format"
            } 
            alt={``}
            style={{ width:"45vw" }}
          />
          <br></br>
          {<image className="image2"></image> !== 'none' && (
            <input
              type="file"
              accept="image/*"
              onChange={(event) => handleImageUpload(items.cowid, event.target.files[0])}
            />
          )}
        </div>
    </div> */}
    {/* <div className="grid-item">
    <div key={items.image3}
        sx={{ alignContent:"center", marginLeft:"auto", marginRight:"auto", width:"40vw"}}
        >
          <img
            src={items["image3"] !== "none" ? 
            "https://sasyavatagro.com/img/farmimg/" + items["image3"] + '?fit=fill&auto=format' :
            "../../images/cow_img.png?fit=fill&auto=format"
            } 
            alt={``}
            style={{ width:"45vw" }}
          />
          <br></br>
          {<image className="image3"></image> !== 'none' && (
            <input
              type="file"
              accept="image/*"
              onChange={(event) => handleImageUpload(items.cowid, event.target.files[0])}
            />
          )}
        </div>
    </div> */}
    {/* <div className="grid-item">
    <div key={items.image4}
        sx={{ alignContent:"center", marginLeft:"auto", marginRight:"auto", width:"40vw"}}
        >
          <img
            src={items["image4"] !== "none" ? 
            "https://sasyavatagro.com/img/farmimg/" + items["image4"] + '?fit=fill&auto=format' :
            "../../images/cow_img.png?fit=fill&auto=format"
            } 
            alt={``}
            style={{ width:"45vw" }}
          />
          <br></br>
          {<image className="image4"></image> !== 'none' && (
            <input
              type="file"
              accept="image/*"
              onChange={(event) => handleImageUpload(items.cowid, event.target.files[0])}
            />
          )}
        </div>
    </div> */}
    {/* <div className="grid-item">
    <div key={items.image5}
        sx={{ alignContent:"center", marginLeft:"auto", marginRight:"auto", width:"40vw"}}
        >
          <img
            src={items["image5"] !== "none" ? 
            "https://sasyavatagro.com/img/farmimg/" + items["image5"] + '?fit=fill&auto=format' :
            "../../images/cow_img.png?fit=fill&auto=format"
            } 
            alt={``}
            style={{ width:"45vw" }}
          />
          <br></br>
          {<image className="image5"></image> !== 'none' && (
            <input
              type="file"
              accept="image/*"
              onChange={(event) => handleImageUpload(items.cowid, event.target.files[0])}
            />
          )}
        </div>
    </div> */}
    {/* <div className="grid-item">
    <div key={items.image6}
        sx={{ alignContent:"center", marginLeft:"auto", marginRight:"auto", width:"40vw"}}
        >
          <img
            src={items["image6"] !== "none" ? 
            "https://sasyavatagro.com/img/farmimg/" + items["image6"] + '?fit=fill&auto=format' :
            "../../images/cow_img.png?fit=fill&auto=format"
            }             
            alt={``}
            style={{ width:"45vw" }}
          />
          <br></br>
          {<image className="image6"></image> !== 'none' && (
            <input
              type="file"
              accept="image/*"
              onChange={(event) => handleImageUpload(items.cowid, event.target.files[0])}
            />
          )}
        </div>
    </div> */}
  </div>
      
    </>
  );
};

export default DetailImageList;
