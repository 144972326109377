import React from 'react';
import { useState, useEffect } from 'react';
import './Toast.css';

const Toast = (props) => {
  const { toastList, position, message } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList(toastList);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
        // if (autoDelete && toastList.length && list.length) {
            deleteToast(toastList.id);
        // }
    }, 5000);
    return () => {
        clearInterval(interval);
    }
}, []);

const deleteToast = id => {
    const index = list.findIndex(e => e.id === id);
    list.splice(index, 1);
    setList([]);
}


  return (
    <>
    <div className={`notification-container ${position}`}>
      {
        list.map((toast, index) =>
        <div key={index}
          className={`notification toast ${position}`}>
            <button onClick={() => deleteToast(toast.id)}>
              x
            </button>
            <div className="notification-image">
              <img src={toast.icon} alt="" width={'20px'} />
            </div>
            <div>
              <p className="notification-title">{toast.title}</p>
              <p className="notification-message">
                  {/* {toast.description} */}
                  {message} 
              </p>
            </div>
        </div>)
      }
      </div>    
    </>
  )
}

export default Toast