import React from 'react'
import { useNavigate } from 'react-router-dom';
import Login from '../Login/Login';
import Register from '../Register/Register';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PhotoFilterSharpIcon from '@mui/icons-material/PhotoFilterSharp';
import DataThresholdingSharpIcon from '@mui/icons-material/DataThresholdingSharp';
import SnowshoeingSharpIcon from '@mui/icons-material/SnowshoeingSharp';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';

const DrawerItems = ( {toggleDrawer }) => {

    const navigate = useNavigate();

    return (
    <Box
      sx={{height:'100%',  width: 250, backgroundColor: '#d4e4fc' }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
      <ListItem key={"item1" } disablePadding>        
          <ListItemButton onClick={()=>{
              // console.log("hello there");            
              toggleDrawer();
              navigate("/Dashboard");
          }}>            
          <ListItemIcon><DataThresholdingSharpIcon /> </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"item2" } disablePadding>        
          <ListItemButton onClick={()=>{
              toggleDrawer();
              navigate("/BirthingSchedule");
          }}>
          <ListItemIcon><PhotoFilterSharpIcon /> </ListItemIcon>
          <ListItemText primary={"Calving Schedule"} />
          </ListItemButton>
        </ListItem>        
        <ListItem key={"item3" } disablePadding>        
          <ListItemButton onClick={()=>{
              toggleDrawer();
              navigate("/CurrentMilking");
          }}>
          <ListItemIcon><PhotoFilterSharpIcon /> </ListItemIcon>
          <ListItemText primary={"Current Milking"} />
          </ListItemButton>
      </ListItem>        
      <ListItem key={"item4" } disablePadding>        
          <ListItemButton onClick={()=>{
              toggleDrawer();
              navigate("/ExpenseTrend");
          }}>
          <ListItemIcon><PhotoFilterSharpIcon /> </ListItemIcon>
          <ListItemText primary={"Expense Trend"} />
          </ListItemButton>
      </ListItem>              
      </List>      
      <Divider />
      <List>
      <ListItem key={"item3" } disablePadding>        
          <ListItemButton onClick={()=>{
              // console.log("hello there");            
              toggleDrawer();
              navigate("/Login");
          }}>
            <ListItemIcon><SnowshoeingSharpIcon /> </ListItemIcon>
          <ListItemText primary={"Login"} />
          </ListItemButton>
        </ListItem>                
        <ListItem key={"item4" } disablePadding>        
          <ListItemButton onClick={()=>{
              // console.log("hello there");            
              toggleDrawer();
              navigate("/Login");
          }}>
            <ListItemIcon><PersonAddAltSharpIcon /> </ListItemIcon>
          <ListItemText primary={"Register"} />
          </ListItemButton>
        </ListItem>        
      </List>
    </Box>
  )
}

export default DrawerItems