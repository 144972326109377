import React from 'react'
import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box';

const ProgressIndicator = () => {
  return (
    <Box sx={{
        display:"flex",
        marginTop:"30%",
        width:"450px",
        height:"95vw",
        justifyContent:"center",
        // display: "inline-block",
        top:"100px",
    }} >
        <CircularProgress color='secondary'></CircularProgress>
    </Box>

  )
}

export default ProgressIndicator