import React, {useState, useEffect} from 'react'
import './BasicImageList.css'
import Title from '../Title/Title';
// import { useNavigate } from 'react-router-dom';
import ImageList from '@mui/material/ImageList';
//import ImageListItem from '@mui/material/ImageListItem';
// import ListSubheader from '@mui/material/ListSubheader';
// import {Stack, Chip } from '@mui/material';
import { BasicImageItems } from './BasicImageItems';
// import { colors } from '@mui/material';

const BasicImageList = ({title, imglist}) => {
    // let navigate = useNavigate();
    const [itemscount, setItemscount] = useState(imglist.length);

    // console.log(imglist);
    // console.log(imglist.length);
    const columnCount =  1; //imglist.length <6 ? 1 : 2 ;
    useEffect(()=>{
      if(itemscount !== imglist.length){
        setItemscount(imglist.length);
      }
    }, [imglist, itemscount]);    

    return (
    <div className='BasicImageListClass'>
    <Title title={title} />
    <div className='BasicImageContent' >
    { itemscount <= 0 ? 
    <div>No item in the list</div>
    : <ImageList  cols={ columnCount } 
        sx={{
          // paddingRight:'20px',
          width:'100%',          
        }}
    >      
    {/* <img src='https://sasyavatagro.com/img/farmimg/cow_img.png'></img> */}
      <BasicImageItems imagelist={imglist} />
    </ImageList>}
    </div>
    </div>
  )
}

export default BasicImageList