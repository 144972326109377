import React from 'react'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import defaultcowimage from '../../images/cow_img.png';
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import DataContext from '../Context/DataContext';
import ProgressIndicator from '../Indicator/ProgressIndicator';

const CowImageItem = ({cowid, imagename, keyno}) => {
const { InitiateRefresh } = useContext(DataContext);
const [isloading, SetIsLoading] = useState(0);
const [imageData, SetImageData]= useState([]);
const [displayImage, SetDisplayImage] = useState(imagename);

useEffect(()=>{

  // console.log(imageData.length);

  if (imageData.length>0) {    

    const UploadImage = async (newImageFile, image_no ) => {

      const formData = new FormData();
      // formData.append("file", data.file[0]);
      formData.append("file", newImageFile.files[0]);
      formData.append("cowid", cowid);
      formData.append("imageno", image_no.substring(image_no.length-1));

      let uploadurl = "https://sasyavatagro.com/repos/api/FileHandler/UploadFarmCow";
      // let uploadurl = "https://localhost:44383/repos/api/FileHandler/UploadFarmCow";
      try{
        SetIsLoading(1);
        const response = await axios.post(uploadurl, formData,         
          );
          // console.log(response.data);
          SetDisplayImage(response.data);
        // console.log(JSON.stringify(response));
        const responsedata = JSON.stringify(response);
        // console.log(responsedata[data]);
        InitiateRefresh();

      }
      catch (err) {
        console.log("error ");
      } finally {
        SetIsLoading(0);
      }
    };

  UploadImage(imageData[0], imageData[1]);

  }

}, [imageData]);

// console.log( { imageid } );
    let imgkeyno = keyno;
    // console.log(imgkeyno);
    // console.log(imgid);
    // console.log(cowid);
    // console.log(`key no ${keyno}`);

    const PaperItem = styled(Paper)(
        ({theme}) => (
          {
            backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            ...theme.typography.body2,
            padding: theme.spacing(1),
            color: theme.palette.text.secondary,
            margin:"10px",
            maxWidth: "746px"
          }
        )
      );

      const handleImageUpload = async (newImageFile, image_no ) => {

        const imagearray = [newImageFile, image_no];
        SetImageData(imagearray);

        // Handle the image upload here and update the API with the new image
        // You will need to implement the API call to update the image for the given ID
        // Example: 
        // fetch(`API_ENDPOINT_URL/${id}`, {
        //   method: 'PUT',
        //   body: formData // FormData containing the new image
        // })
        // .then(response => response.json())
        // .then(data => console.log('Image updated successfully:', data))
        // .catch(error => console.error('Error updating image:', error));
        // formData.append("file", data.file[0]);
        // console.log(JSON.stringify(formData));
        // console.log(newImageFile);
        // console.log(image_no);
        // console.log(imgkeyno);
        // console.log(newImageFile.name);
        // console.log(newImageFile.name.substring(newImageFile.name.length - 1));
        // return;
            // {            
            //   headers: {
            //     "Content-Type": "multipart/form-data",
            //   }
            // },
          // console.log(responsedata[data]);         
      };

      // if(cowid=='74') console.log("ooooooooo");
      // const img_input = document.getElementById("uplImage");
      // img_input.style.opacity = 1;

      
    return (
    <>
    {isloading && <ProgressIndicator />}
        {!isloading && <PaperItem key={keyno}
        sx={{ position:"relative", alignContent:"center", marginLeft:"auto", marginRight:"auto", 
          display: "inline-block", overflow: "hidden", padding:"20px"}}
        >
          <img
            src={displayImage !== "none" ? 
            "https://sasyavatagro.com/img/farmimg/" + displayImage + '?fit=contain&auto=format' :
             defaultcowimage+ '?fit=contain&auto=format'
            } 
            alt={``}
            style={{ position: 'relative', float:"center", display : "inline-block", maxWidth:"100%" }}
          />
          <br>
          </br>
          <div>
          <label           
          htmlFor=
          // '' for=
          "uplImage"      
            style={{              
              display:'inline-block',
              verticalAlign:'middle',
              fontWeight:'bold',
              color: 'black',
              background: '#dddddd',
              borderRadius:"10px",
              width:'265px',
              height:'50px',
              paddingTop:'5px',
            }}    
            >{ displayImage === "none" ? "Add Image.." : "Update Image.."} 
              <span style={{fontWeight:"bold", color: '#000000',
                  }} ></span>
            <input
                type="file"
                accept="image/*"
                name={"uplImage" + imgkeyno}
                id= "uplImage"
                alt="upload"
                onChange={(event) => handleImageUpload(event.target, imgkeyno )}    
                style={{opacity:1}}
              />
              </label>                                  
            </div>
            
        </PaperItem>}
    </>
  )
}

export default CowImageItem