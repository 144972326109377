import React, {useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2';
import ProgressIndicator from '../Indicator/ProgressIndicator';
import { Box } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  function randomInteger(max) {
    return Math.floor(Math.random()*(max + 1));
    }

  function randomRgbColor() {
    let r = randomInteger(255);
    let g = randomInteger(255);
    let b = randomInteger(255);
    return [r,g,b];
}

function randomHexColor() {
    let [r,g,b] =randomRgbColor();

    let hr = r.toString(16).padStart(2, '0');
    let hg = g.toString(16).padStart(2, '0');
    let hb = b.toString(16).padStart(2, '0');

    return "#" + hr + hg + hb;
}

const ExpenseTrendChart = ({ title, data }) => {
    const [dataItems, setDataItems]= useState([]);
    const [dataLabels, setDataLabels]= useState([]);
    const [chartdata, setChartData]= useState([]);
    const [chartReady, setChartReady] = useState(0);
  
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
        //   title: {
        //     display: true,
        //     text: 'Total Milk by Cow',
        //   },
        },
        scales: {
          y: {
            min: 0,      
              ticks: {
               stepSize: 100, 
              }      
            }
        }
      };

    useEffect(()=>{
        if(data && dataItems.length < 1)
        {
            if(1){}
            // console.log(dataItems.length)
            var lst = data.map((item)=>{
                // console.log(`type ${title}`)
                return item['TOTAL']
            })
        // console.log(lst);
        setDataItems(lst);

            var lbl = data.map(item => {
                return item['YEARMONTH']
            })
        // console.log(lbl);
        setDataLabels(lbl);
        
            }                        
        })

        useEffect(()=>{
            if(dataItems.length>0 && dataLabels.length > 0){
                const cd = {
                    labels: dataLabels,
                    datasets: [
                      {
                        label: title,
                        data: dataItems,
                        // borderColor: '#0f03fc',
                        borderColor: randomHexColor(),
                        // backgroundColor: '#eae8fa',
                        backgroundColor: randomHexColor(),
                        borderWidth: 1,
                      },
                    ],
                  };
                  setChartData(cd);
                  setChartReady(1)
            }

        },[dataItems,dataLabels ])

    return (    
    <>    
        { chartReady === 1  ?  
            <Box sx={{position:'relative', width:'98%', 
                paddingTop:'20px', marginLeft:'10px', marginRight:'20px'}}>
            <Bar options={options} data={chartdata} sx={{        
            innerWidth:'80%',
            justifyContent: 'center'
                }} /> 
      </Box>
    : 
    // <></>
    <ProgressIndicator />
    } 
    </>    
  )
  
}

export default ExpenseTrendChart