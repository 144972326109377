import {createContext, useEffect, useState} from 'react'

const FetchContext = createContext();

export const FetchProvider= (props) =>{

    const [milkTrendData, setMilkTrendData] = useState([]);  
    const [ismilkTrendDataLoading, setmilkTrendDataLoading]=useState(false);  
    const [milkTrendDataError, setMilkTrendDataError] = useState(null);

    useEffect(()=>{
      const getmilkTrendData = async ()=> {        
        setmilkTrendDataLoading(true);
        // setUrl("");          
          try{
            // const response = await Api.get(`https://sasyavatagro.com/repos/api/dairyfarm/GetMilkTrendData`);
            const response = await fetch('https://sasyavatagro.com/repos/api/dairyfarm/GetMilkTrendData');
            if (!response.status) throw Error("Failed to fetch data");
            const listItems = await response.json();
            setmilkTrendDataLoading(false);
            console.log(listItems);
            setmilkTrendDataLoading(listItems);
            setMilkTrendDataError(null);                   
          }
          catch (err){
            setMilkTrendDataError(err.message);
            setMilkTrendData([]);
          }
          finally{
            setmilkTrendDataLoading(false);
          }
        }

        // console.log(url);        
        console.log(ismilkTrendDataLoading);    
        // setUrl("");    

      getmilkTrendData();
    },[]);
    
      return (
        <FetchContext.Provider value={{
          milkTrendData,
          ismilkTrendDataLoading, 
          milkTrendDataError
        }}>
            { props.children }
        </FetchContext.Provider>
    )
}

export default FetchContext