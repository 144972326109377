import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Title from '../Title/Title';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import '@fontsource/roboto';
import { Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
//import ProgressIndicator from '../Indicator/ProgressIndicator';
import DataContext from '../Context/DataContext';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Slide from '@mui/material/Slide';
import Api from '../GetData/Api';
import axios from 'axios';
import OptionsDialog from '../Dialog/OptionsDialog';
import DetailImageList from './DetailImageList';
import './CowDetail.css';
import Toast from '../Toast/Toast'
import { TOAST_PROPERTIES } from '../Toast/ToastProperties';
import { CowDetailTextItem } from './CowDetailTextItem';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaperItem = styled(Paper)(
  ({theme}) => (
    {
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      margin:"10px",
      maxWidth: "746px"
    }
  )
);

const CowDetail = ({ cowid }) => {

  const [items, setItems] = useState([]);  
  const [isLoading, setLoadingValue]=useState(false);  
  const [fetchError, setFetchError] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [title, setTitle]  = useState("");
  const [type, setType]=useState("");
  const [content, setContent]=useState("");
  const [dialogValue, setDialogValue]=useState("");
  const [selectedOption, setSelectedOption]=useState(0);  
  const [updateType, setUpdateType]=useState(0);
  const [newcowtag, setNewCowTag]=useState("");
  const [toastList, setToastList]=useState([]);
  const [toastMsg, SetToastmsg]=useState('');
  const { InitiateRefresh } = useContext(DataContext);

  let navigate = useNavigate();

  const handleDialogOpen = (_title, _type, _content, _updtype) => {
    setTitle(_title);
    setType(_type);
    setContent(_content);
    setOpenDialog(true);
    setUpdateType(_updtype);
  };

  let updatevalue = -100;

  const valueItems = 
    [
      'Adult Female', 
      'Heifer - Female', 
      'Heifer - Male', 
      'Bull', 
      'Send to Gaushala', 
      'Expired',
      'Duplicate',
      'Suspense'
    ];

  const addNewCow = async ()=>{
    // console.log('hello ' + newcowtag);
    const API_URL = 'https://sasyavatagro.com/repos/api/dairyfarm/addnewcow/' + newcowtag;
      try{
        const response = await fetch(API_URL);
        if (!response.status) throw Error("Failed to fetch data");
        updatevalue = await response.json();

        if (updatevalue === 1) {
          InitiateRefresh();
          ShowToast('success', 'Successfully added new Cow');        
          setInterval(() => {
            navigate("/cowlist", { replace: true });
        }, 7000);          
        } else if (updatevalue === -1) {
          ShowToast('warning', 'Tag already exists');        
        }
      }
      catch (err) {
        console.log(err);
        ShowToast('danger', err + ' ' +'some error has happened');        
      }
      finally {
        console.log("updatevalue " + updatevalue);
        setNewCowTag("");
      }    
  };

  const ShowToast = (type, msg)=> {
    const list = TOAST_PROPERTIES.find((toast) => toast.title.toLowerCase() === type);
    SetToastmsg(msg);
    setToastList([...toastList, list]);
    setInterval(() => {
      setToastList([]);
  }, 7000);
    
  };

  const handleDialogClose = async (option) => {
  // console.log("type: " + updateType);
  // console.log("option: " + option.updateType);
    if(option.updateType === 1)
    {
      console.log("1")
    } else if(option.updateType === 2)
    {
      const updatedRem = { cowid: routeParams.cowid, remark: dialogValue };
      // console.log("this is 2 " + dialogValue)
      try{
        setLoadingValue(true);
        // const response = await Api.post(`/UpdateCowRemarks`, updatedRem);
        const response = await axios.post('https://sasyavatagro.com/repos/api/dairyfarm/UpdateCowRemarks', updatedRem);

        if (response.status !== 200) throw Error("Failed to fetch data");
        
        // console.log("response data " + response.data);
        // setopenSnack(true);
      } catch (err){

        console.log(`Error: ${err.message}`);
        
      } finally {
          // console.log("option "+ option.updateType);    
          // console.log("selectedoption "+selectedOption);        
          setSelectedOption(option.updateType);
          setLoadingValue(false);
      }

      setDialogValue("");
    } else if(option.updateType === 3){
      const updatedRem = { cowid: routeParams.cowid, cowtag: dialogValue };
      try{
        setLoadingValue(true);
        // const response = await Api.post(`/UpdateCowTag`, updatedRem);
        const response = await axios.post('https://sasyavatagro.com/repos/api/dairyfarm/UpdateCowTag', updatedRem)
        if (response.status !== 200) throw Error("Failed to fetch data");
        console.log("response data " + response.data);
        InitiateRefresh();
        // setopenSnack(true);
      } catch (err){
        console.log(`Error: ${err.message}`);        
      } finally {
          console.log("option "+ option.updateType);    
          console.log("selectedoption "+selectedOption);        
          setSelectedOption(option.updateType);
          setLoadingValue(false);
      }
      setDialogValue("");
    }
    setOpenDialog(false);
  };

  // useEffect(()=>{
  //   console.log("changing");

  // }, [selectedOption]);

  const routeParams = useParams();
  // console.log(routeParams.cowid);
  const isNew = routeParams.cowid;

    useEffect(()=>{
      // console.log("In use effect");
      // console.log("Hello");          
      const API_URL = 'https://sasyavatagro.com/repos/api/dairyfarm/DetailbyId?cowid=' + routeParams.cowid;

      // console.log(API_URL);
      const fetchItems = async ()=>{
        setLoadingValue(true);
        try{
          const response = await fetch(API_URL);
          if (!response.status) throw Error("Failed to fetch data");
          const listItems = await response.json();
          // console.log(listItems);
          setItems(listItems);
          setFetchError(null);                   
        }
        catch (err){
          setFetchError(err.message);
          setItems([]);
        }
        finally{
          setLoadingValue(false);
          setSelectedOption(0);
        }
      }
    
      // console.log("in update state " + selectedOption);

      if (selectedOption !== 1) {
        fetchItems();
      }
      // setItems(data);

    }, [routeParams.cowid, selectedOption]); 

    // useEffect({ 
    //   console.log("");
    // }, [openOptionDialog]);

    const UpdateOptionDialogData = async (val, updateType)=>{
      console.log("Update value " + val + " updtype " + updateType);
      // setDialogValue(value);

      if (updateType === 4) {
        const updatedRem = { cowid: routeParams.cowid, status: val };
        try{
          setLoadingValue(true);
          const response = await Api.post(`https://sasyavatagro.com/repos/api/dairyfarm//UpdateCowStatus`, updatedRem);
          console.log (JSON.stringify(updatedRem));          
          console.log (JSON.stringify(response));
          if (response.status !== 200) throw Error("Failed to fetch data");
          // console.log("response data " + response.data);
          // setopenSnack(true);
          InitiateRefresh();
        } catch (err){
          console.log(`Error: ${err.message}`);        
          
        } finally {
            // console.log("option "+ option.updateType);    
            // console.log("selectedoption "+selectedOption);        
            // setSelectedOption(option.updateType);
            setLoadingValue(false);
        }      
      }

      setSelectedOption(val);
      setLoadingValue(false);

    }

    return (      
    <>
    <Title title={"Cow detail"}/>
    { isLoading  && <div style={{marginTop:'100px'}}> <CircularProgress color='secondary' /> </div>}
    {fetchError && <p style={{ color: "red" }}>{`Error: ${fetchError}`}</p>}
    { !isLoading && (
      <Box sx={{ width: '90%', flex:1, display:"inline-block", alignContent:"center"}}>
        <Stack spacing={2} >
        { isNew != 0 && <>
          <PaperItem>
            <Grid container spacing={0}>
              <Grid item xs={6}  textAlign="left">                
                <Typography variant="subtitle2" gutterBottom  sx={{
                    fontWeight:"600"
                  }}>
                 Cow Tag: { items.tag }                                     
                 <EditIcon  color='primary' onClick={ ()=>{
                      handleDialogOpen("Add Tag", 
                      "text", 
                      "Enter yourTag below",
                      3);
                    }              
                  }></EditIcon>
                </Typography>             
              </Grid>              
              <Grid item xs={6} textAlign="right">
                  { items.status && <OptionsDialog dialogClose={ UpdateOptionDialogData } val={ items.status } 
                      openOption = { false } 
                      updateType= { 4 } valueList = { valueItems } /> }
              </Grid>
            </Grid>
          </PaperItem>     
          {/* <CowDetailTextItem val={items.tag} title={"Cow Tag"}/> */}
          <PaperItem>
            <Grid container spacing={0}>
              <Grid item xs={6}  textAlign="left">
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
                Body Weight: { items.bodyweight }
                </Typography></Grid>
                <Grid item xs={6} textAlign="right"><Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}> { items.breed } </Typography></Grid>
            </Grid>
          </PaperItem>                
          <PaperItem>
            <Grid container spacing={0}>
            <Grid item xs={6} textAlign="left"> 
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
              Last milk Qty 
              <EditIcon  color='primary' onClick={ ()=>{
              navigate("/UpdateMilk/" + routeParams.cowid, { replace: false });
              }              
            } />
              </Typography>
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}>
              { items.lastmilkingqty }
              </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right"> 
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
              Max milking Qty              
              </Typography>
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}>
               { items.maxmilkingqty }               
               </Typography>
               
               </Grid>
            </Grid>
          </PaperItem>                          
          <PaperItem>
          <Grid container spacing={1} justifyContent="start">
          <Grid item xs={6}  textAlign="left">
          <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
            Last Delivery Date:
            </Typography>
            <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}>
             { items.lastdeliverydate && items.lastdeliverydate.substring(0, 10)  }
             </Typography>
             </Grid>
          <Grid item xs={6} textAlign="right"> 
          <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
          Days since delivery 
          </Typography>
          <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}>
          {items.dayssincelastdelivery }
          </Typography>
          </Grid>
          </Grid>
              </PaperItem>          
          <PaperItem>
            <Grid container spacing={1} justifyContent="start">
              <Grid item xs={6} textAlign="left">
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
                Last Heat Date: 
                </Typography>
                <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}>
                { items.heatdate && items.heatdate.substring(0, 10)  }
                </Typography>
                </Grid>
              <Grid item xs={6} textAlign="right"> 
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
              Days since Heat 
              </Typography>
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}>
              {items.dayssinceheat }
              </Typography>
              </Grid>
            </Grid>
          </PaperItem>
          <PaperItem>
            <Grid container spacing={1} justifyContent="start">
              <Grid item xs={6} textAlign="left">              
              <Badge badgeContent={ items.ivrepeat } color="primary">
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
                Last Iv Date:
                </Typography>
                <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}>
                 { items.ifdate && items.ifdate.substring(0, 10)  }
                 </Typography>
                </Badge>
              </Grid>
              <Grid item xs={6} textAlign="right"> 
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
              Days since IV
              </Typography>
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}>
               {items.dayssinceiv }
               </Typography>
               </Grid>
            </Grid>
          </PaperItem> 
          <PaperItem>
            <Grid container spacing={1} justifyContent="start">
              <Grid item xs={6} textAlign="left">
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
                Father:
                </Typography>
                <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}> { items.father  }
                </Typography>
                </Grid>
              <Grid item xs={6} textAlign="right"> 
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"
                }}>
              Mother
              </Typography>
              <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}>
               {items.mother }
               </Typography>
               </Grid>
            </Grid>
          </PaperItem>
          <PaperItem>
          <Grid container spacing={1} justifyContent="start" padding={"10px"}>
          <Grid item xs={12} textAlign="left">
          <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"600"                  
                }}>
            Remarks:                         
            <EditIcon color='primary' onClick={ ()=>{
              handleDialogOpen("Add Remarks", 
              "text", 
              "Enter your remarks below", 2);
              }              
            }
            ></EditIcon>            
            
            </Typography>
            </Grid>
            <Grid item xs={12} textAlign="left">
            <Typography variant="subtitle2" gutterBottom  sx={{
                  fontWeight:"400"
                }}>                                                  
             {items.remarks}                         
             </Typography>
             </Grid>
            </Grid>
          </PaperItem>
          </>}
          { isNew != 0 &&
          <DetailImageList items={items} /> }
          {/* <CowDetailImageList items={items} /> */}
          { isNew == 0 &&
          <>
          <Box display="flex" justifyContent='center' 
            sx={{ paddingTop:'40px', marginLeft:'40px', marginRight:'40px', 
            paddingLeft:'40px', paddingRight:'40px', borderRadius:'20px' }}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                // label="Email Address"
                label ="New Cow Tag"
                // type="email"
                type="text"
                value={newcowtag}
                onChange={e => {
                  setNewCowTag(e.target.value)
                }}        
                fullWidth
                variant="standard"
              />
          </Box> 
          <Box display="flex" justifyContent='center' 
          sx={{ paddingTop:'40px', 
              paddingLeft:'40px', paddingRight:'40px', borderRadius:'20px' }}>
              <Button variant='contained' 
                sx={{width:'150px'}}
                onClick={()=>{                 
                  // console.log('hello');
                  addNewCow();
              }}>Save</Button>
            </Box>   
            <Box display="flex" justifyContent='center' sx={{width:"300px"}}>
            <Toast 
              toastList={toastList}
              position="bottom-center"
              message={toastMsg}
          />
              </Box>         
          
          </>          
          }
        </Stack>
      </Box>    
    
    ) 
    }

<Dialog open={openDialog} 
        onClose={()=>{
        handleDialogClose(1)
      } } 
      TransitionComponent={Transition}
      >
    <DialogTitle>{title} </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {content}        
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        // label="Email Address"
        label ={title}
        // type="email"
        type={type}
        value={dialogValue}
        onChange={e => {
          setDialogValue(e.target.value)
        }}        
        fullWidth
        variant="standard"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={()=>{
        handleDialogClose(1)
      } }>Cancel</Button>
      <Button onClick={()=>{
        handleDialogClose( {updateType} )
      } }>Update</Button>
    </DialogActions>
  </Dialog>
  {/* <SimpleSnackbar openSnack={openSnack} /> */}


    </>
  )
}

export default CowDetail