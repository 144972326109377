import React, { useContext, useState, useEffect }  from 'react'
import DataContext from '../Context/DataContext';
import BasicImageList from './BasicImageList';

const AwaitingIVImageList = () => {
    const { isLoading, awaitingAiList } = useContext(DataContext);
    const [imagelist, setImageList] = useState([]);
    // console.log(awaitingAiList)
    useEffect(()=>{
      if (!isLoading){
        setImageList(
          awaitingAiList.map(item =>{         
            const infoitem = item.dayssinceiv < item.dayssinceheat ? `Days since IV ${item.dayssinceiv}` :
            `Days since Heat ${ item.dayssinceheat}`;
            const infolist = [
              `Days since last delivery ${item.dayssincelastdelivery}`,
              infoitem,
              `Days since deworming ${item.dayssincedeworming ===999?'NA': item.dayssincedeworming}`
            ];

            return (
                [
                    item.cowid,
                    item.image1,
                    item.tag,
                    item.status,
                    item.isMilking,
                    item.isVerified,
                    item.status,
                    item.dayssincelastdelivery,
                    item.dayssinceiv,
                    infolist
                ]
            );        
        })
        );
      }
    },[isLoading, awaitingAiList]);    
      
//.filter(value => (value[6] === 'Adult Female') && value[8] > value[7] && value[7] > 50)

  return (
    <BasicImageList title={"Pending IV List"} imglist={imagelist}  />
    // <div>hello</div>
  )
}

export default AwaitingIVImageList