import React from 'react'
import { Typography } from '@mui/material'

const MilkingRangeList = ({Title, Description, Data}) => {

  return (
<div>
    <Typography fontSize={14} fontWeight={'bold'} color={'darkblue'}
    sx={{paddingBottom:'5px'}}
      >{Title}</Typography>
      
      <Typography fontSize={12} fontWeight={'bold'} color={'darkblue'}
      sx={{paddingBottom:'10px'}}
      >{Description}</Typography>
      
     { 
      Data.map((item, index) => (
        <Typography fontSize={12} fontWeight={'bold'} color={'brown'}
        key={index}
        sx={{paddingBottom:'5px'}}
        >{item}</Typography>
        // <p>{item}</p>
      ))
    }
    </div>
  )
}

export default MilkingRangeList