import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import HighlightBoard from '../Indicator/HighlightBoard';
import WhileLoading from '../Indicator/WhileLoading';
import ImageLabels from './ImageLabels';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export const BasicImageItems = (imagelist) => {
    let navigate = useNavigate();
    const [imageLoaded, setImageLoaded]=useState(0);

    // console.log(imagelist)

    const LoadComplete = ()=>{
        setImageLoaded(1);
      }

  return (
    <>
{imagelist['imagelist'].map((item, index) => (
    <ImageListItem key={index} 
    sx={{
      marginRight:'30px',
      width:'95%',
      paddingTop:'12px',
      paddingLeft:'10px',
      paddingRightL: '10px'
    }}
    >
      <ImageListItemBar 
      title={ item[3] + " : "  + item[2] }
      position="top"
      actionIcon={
        <IconButton
        aria-label={'X'}
        sx={{
          color:'white'
        }}
        onClick={
          ()=>{
              navigate("/cowdetail/"+ item[0], { replace: false })
              // console.log("Hello");
          }
      }
>
<InfoIcon />
</IconButton>
      }
      sx={{
        marginLeft:'10px',
        zIndex:900,
        borderTopLeftRadius:7,
        borderTopRightRadius:7
      }}
      />
    <img
          src={`${"https://sasyavatagro.com/img/farmimg/" + item[1]}?w=248&fit=crop&auto=format`}
          srcSet={`${"https://sasyavatagro.com/img/farmimg/" + item[1]}?w=248&fit=crop&auto=format&dpr=2 2x`}
          alt={item[0]}
          loading="lazy"
          onLoad={LoadComplete}          
        />      
    <HighlightBoard cowid={item[0]} isverified={item[14]} ismilking={item[13]}/>        
    {imageLoaded ? <ImageLabels labels={item[item.length-1]} /> :<WhileLoading />}    
    </ImageListItem>
      ))
    }
    </>
  )
}
