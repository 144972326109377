import React from 'react'
import { useState }  from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import Drawer from '@mui/material/Drawer';
// import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logo from '../../images/k.png';
import DrawerItems from './DrawerItems';

// const drawerWidth = 240;

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false);  
  const navigate = useNavigate();
  const location = useLocation();
  
  const toggleDrawer = ()=>{
    setOpenDrawer(!openDrawer);
    // console.log(openDrawer);
  }

  const ClickLogo = () =>{
    const currentroute =  location.pathname;
    if (currentroute !== "/") {
      navigate("/");
    }
  }

  return (
    <>
    <AppBar position="sticky" color="primary">
        <Toolbar>
        <img style={{height:"38px"} } src={logo} onClick={ClickLogo} alt="logo"/>
          {/* The Typography component applies 
           default font weights and sizes */}
  
          <Typography variant="h6" 
            component="div" sx={{ flexGrow: 1 }}>
            Happy Cow
          </Typography>
          <Button color="inherit">Login</Button>
                    {/*Inside the IconButton, we 
           can render various icons*/}
          <IconButton
          onClick={toggleDrawer}
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            {/*This is a simple Menu 
             Icon wrapped in Icon */}
            <MenuIcon />
          </IconButton>

        </Toolbar>
      </AppBar>
      <Drawer
      variant='temporary'
      anchor='left'
      elevation={2}
      open={openDrawer}
      onClose={toggleDrawer}
      
      >
        <DrawerItems  toggleDrawer={toggleDrawer}/>
      </Drawer>
      </>
  )
}

export default Header