import React, { useContext, useEffect, useState }  from 'react'
import './BasicImageList.css'
import DataContext from '../Context/DataContext';
import BasicImageList from './BasicImageList';
import ProgressIndicator from '../Indicator/ProgressIndicator';

const DewormingImageList = () => {
    const { isLoading, items } = useContext(DataContext);
    const [imagelist, setImageList] = useState([]);
    // console.log(isLoading);

    useEffect(()=>{
      if (!isLoading){
        console.log(items);
        setImageList(
          items.map(item => {
            const infolist = [
              `Days since last delivery: ${item.dayssincelastdelivery}`,
              `Days since IV: ${item.dayssinceiv===999?'NA': item.dayssinceiv}`,
              `Days since deworming: ${item.dayssincedeworming ===999?'NA': item.dayssincedeworming}`
            ];
            return(
              [
                item.cowid,
                item.image1,
                item.tag,
                item.status,
                item.isMilking,
                item.isVerified,
                item.status,
                item.dayssincelastdelivery,
                item.dayssinceiv,
                item.dayssincedeworming,
                infolist
            ]
            );
          }
          ).filter(value => (value[9] > 90))        
          );
      }

    }, [isLoading]);

    
  return (
    <>
     {isLoading && <ProgressIndicator /> }
{    !isLoading && <BasicImageList title={"Deworming List"} imglist={imagelist}  />}
    </>
  )
}

export default DewormingImageList